import { Center } from '@chakra-ui/react';
import { ArrangementDoc } from '@qupidu/hosting-common/src/common/collections/Arrangements';
import Catch from '@qupidu/hosting-common/src/components/Catch';
import LogoIcon from '@qupidu/hosting-common/src/components/LogoIcon';
import { DocumentReference } from 'firebase/firestore';
import { Suspense, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ErrorFallbackScreen from '../ErrorFallbackScreen';
import ArrangementForm from './ArrangementForm';

export function ArrangementCreateScreenMain() {
  const navigate = useNavigate();

  useEffect(
    () => {
      const onClick = () => navigate('..');

      window.Telegram.WebApp.BackButton.onClick(onClick);
      window.Telegram.WebApp.BackButton.show();

      return () => {
        window.Telegram.WebApp.BackButton.hide();
        window.Telegram.WebApp.BackButton.offClick(onClick);
      };
    },
    [navigate],
  );

  const onComplete = useCallback(
    (arrangementRef: DocumentReference<ArrangementDoc>) => {
      navigate(`/arrangements/${arrangementRef.id}`);
    },
    [navigate],
  );

  return (
    <ArrangementForm
      onComplete={onComplete}
    />
  );
}

export default function ArrangementCreateScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <ArrangementCreateScreenMain />
      </Suspense>
    </Catch>
  );
}
