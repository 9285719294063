import {
  Center,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useVenturesCollectionRef } from '@qupidu/hosting-common/src/common/collections/Ventures';
import Catch from '@qupidu/hosting-common/src/components/Catch';
import LogoIcon from '@qupidu/hosting-common/src/components/LogoIcon';
import useDocumentSnapshot from '@qupidu/hosting-common/src/hooks/useDocumentSnapshot';
import { doc } from 'firebase/firestore';
import { Suspense, useEffect } from 'react';
import { LuAlertTriangle } from 'react-icons/lu';
import { useNavigate, useParams } from 'react-router-dom';

import ErrorFallbackScreen from '../ErrorFallbackScreen';
import VentureRow from './VentureRow';

export function VenturesScreenMain() {
  const navigate = useNavigate();
  useEffect(
    () => {
      const onClick = () => {
        window.Telegram.WebApp.HapticFeedback.impactOccurred('soft');

        navigate('..');
      };

      window.Telegram.WebApp.BackButton.onClick(onClick);
      window.Telegram.WebApp.BackButton.show();

      return () => {
        window.Telegram.WebApp.BackButton.hide();
        window.Telegram.WebApp.BackButton.offClick(onClick);
      };
    },
    [navigate],
  );

  const { ventureId } = useParams<'ventureId'>();

  const venturesCollectionRef = useVenturesCollectionRef();
  const { snap: ventureSnap } = useDocumentSnapshot(
    doc(
      venturesCollectionRef,
      ventureId,
    ),
  );

  if (!ventureSnap || (!ventureSnap.exists() && ventureSnap.metadata.fromCache)) {
    return (
      <Center h="100%">
        <LogoIcon boxSize={16} />
      </Center>
    );
  }

  if (!ventureSnap.exists()) {
    return (
      <Center h="100%">
        <VStack>
          <Icon as={LuAlertTriangle} boxSize={10} />
          <Text>Venture not found</Text>
        </VStack>
      </Center>
    );
  }

  return (
    <VentureRow ventureSnap={ventureSnap} />
  );
}

export default function VenturesScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <VenturesScreenMain />
      </Suspense>
    </Catch>
  );
}
