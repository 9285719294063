import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';

import type Preferences from '../Preferences';

import { useFirestore } from '../../components/FirestoreProvider';
import AppLanguage from '../AppLanguage';
import { type CityDoc } from './Cities';
import { type PictureDoc } from './Picture';
import { type ProfileDoc, type ProfileField } from './Profiles';

export enum TripStatus {
  DRAFTED = 'DRAFTED',
  PAID = 'PAID',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export interface TripDoc {
  _v: 1;
  checkoutSessionUrl?: string;
  cityRef: DocumentReference<CityDoc>;
  createdAt: Timestamp;
  description: string;
  endsAt: Timestamp;
  femaleParticipantPlaces: number;
  femaleProfilePreferences?: Preferences;
  femaleProfileRequiredFields?: ProfileField[];
  indexedAt?: Timestamp;
  instagramTag?: string;
  maleParticipantPlaces: number;
  maleProfilePreferences?: Preferences;
  maleProfileRequiredFields?: ProfileField[];
  name: string;
  organizerRef: DocumentReference<ProfileDoc>;
  pictureRefs: DocumentReference<PictureDoc>[];
  publishedAt?: Timestamp;
  startsAt: Timestamp;
  status: TripStatus;
  translations?: Partial<Record<AppLanguage, Partial<Pick<TripDoc, 'description' | 'name'>>>>;
  unpublishedAt?: Timestamp;
  updatedAt: Timestamp;
}

export const isTripDoc = (
  data?: DocumentData,
): data is TripDoc => true;

export const isTripRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<TripDoc> => ref.parent.id === 'trips';

function isTripSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<TripDoc>;
function isTripSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<TripDoc>;
function isTripSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<TripDoc> | QueryDocumentSnapshot<TripDoc> {
  return isTripRef(snap.ref);
}

export { isTripSnap };

export const getTripsCollectionRef = (firestore: Firestore) => collection(firestore, 'trips') as CollectionReference<TripDoc>;

export const useTripsCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => getTripsCollectionRef(firestore), [firestore]);
};
