import {
  Center,
  Container,
  HStack,
  VStack,
} from '@chakra-ui/react';
import Catch from '@qupidu/hosting-common/src/components/Catch';
import { useInsets } from '@qupidu/hosting-common/src/components/InsetsProvider';
import LogoFull from '@qupidu/hosting-common/src/components/LogoFull';
import LogoIcon from '@qupidu/hosting-common/src/components/LogoIcon';
import { Suspense, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ErrorFallbackScreen from '../ErrorFallbackScreen';
import ProfileForm from './ProfileForm';

export function MyProfileUpdateScreenMain() {
  const navigate = useNavigate();
  useEffect(
    () => {
      const onClick = () => navigate('..');

      window.Telegram.WebApp.BackButton.onClick(onClick);
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.enableClosingConfirmation();

      return () => {
        window.Telegram.WebApp.BackButton.hide();
        window.Telegram.WebApp.BackButton.offClick(onClick);
        window.Telegram.WebApp.disableClosingConfirmation();
      };
    },
    [navigate],
  );

  const insets = useInsets();

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack h={12} justifyContent="center">
          <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
        </HStack>
      </Container>

      <ProfileForm />
    </VStack>
  );
}

export default function MyProfileUpdateScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <MyProfileUpdateScreenMain />
      </Suspense>
    </Catch>
  );
}
