import {
  AspectRatio,
  Box,
  Center,
  Container,
  Grid,
  Text,
} from '@chakra-ui/react';
import {
  TripStatus,
  useTripsCollectionRef,
} from '@qupidu/hosting-common/src/common/collections/Trips';
import Catch from '@qupidu/hosting-common/src/components/Catch';
import useQuerySnapshot from '@qupidu/hosting-common/src/hooks/useQuerySnapshot';
import {
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Trip, { TripLoading } from './Trip';

export function TripGridLoading() {
  return (
    <Grid gap={2} gridAutoRows="1fr" templateColumns="repeat(2, 1fr)">
      <AspectRatio ratio={9 / 16}>
        <TripLoading />
      </AspectRatio>
      <AspectRatio ratio={9 / 16}>
        <TripLoading />
      </AspectRatio>
      <AspectRatio ratio={9 / 16}>
        <TripLoading />
      </AspectRatio>
      <AspectRatio ratio={9 / 16}>
        <TripLoading />
      </AspectRatio>
    </Grid>
  );
}

export function TripGridMain() {
  const { t } = useTranslation('TripsScreen', { keyPrefix: 'TripList' });

  const tripsCollectionRef = useTripsCollectionRef();
  const { snap: tripsSnap } = useQuerySnapshot(
    query(
      tripsCollectionRef,
      where('status', '==', TripStatus.PUBLISHED),
      orderBy('startsAt', 'asc'),
      limit(100),
    ),
  );

  if (!tripsSnap) {
    return (
      <TripGridLoading />
    );
  }

  if (tripsSnap.docs.length) {
    return (
      <Grid autoRows="1fr" gap={2} templateColumns="repeat(2, 1fr)">
        {tripsSnap.docs.map((tripSnap) => (
          <AspectRatio
            key={tripSnap.id}
            ratio={9 / 16}
          >
            <Trip
              scrollSnapAlign="center"
              tripSnap={tripSnap}
            />
          </AspectRatio>
        ))}

        {new Array(Math.ceil(tripsSnap.docs.length / 2) * 2 - tripsSnap.docs.length)
          .fill(null)
          .map((_, i) => (
            <Box
              _dark={{
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
              }}
              _light={{
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
              }}
              borderRadius="md"
              // eslint-disable-next-line react/no-array-index-key
              key={i}
            />
          ))}
      </Grid>
    );
  }

  return (
    <Container height="100%" maxW="lg">
      <Center height="100%">
        <Text textAlign="center">
          {t('emptyList.body')}
        </Text>
      </Center>
    </Container>
  );
}

export default function TripGrid() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<TripGridLoading />}>
        <TripGridMain />
      </Suspense>
    </Catch>
  );
}
