import {
  Button,
  Container,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { ProfileVersionDoc } from '@qupidu/hosting-common/src/common/collections/Profiles';
import Language from '@qupidu/hosting-common/src/common/Language';
import CheckboxFormControl from '@qupidu/hosting-common/src/components/CheckboxFormControl';
import { useInsets } from '@qupidu/hosting-common/src/components/InsetsProvider';
import { useIPData } from '@qupidu/hosting-common/src/components/IPDataProvider';
import LogoFull from '@qupidu/hosting-common/src/components/LogoFull';
import { Formik } from 'formik';
import _ from 'lodash';
import { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export type Props = {
  data: Pick<ProfileVersionDoc, 'languages'> | undefined;
  onComplete: (data: Required<Pick<ProfileVersionDoc, 'languages'>>) => void;
};

export function LanguagesFormMain({ data, onComplete }: Props) {
  const { t } = useTranslation('WelcomeScreen', { keyPrefix: 'ProfileForm' });

  const schema = useMemo(
    () => yup.object().shape({
      languages: yup
        .array(yup
          .string<Language>()
          .label(t('languages.item.label'))
          .oneOf(Object.values(Language))
          .required())
        .label(t('languages.label'))
        .min(1)
        .required(),
    }),
    [t],
  );

  const ipData = useIPData();

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => {
      const ipDataLanguages: Language[] = ipData?.languages.map(
        (l) => (l as unknown as { code: Language }).code,
      ) ?? [];

      const navigatorLanguages: Language[] = ([navigator.language, ...navigator.languages])
        .map((l) => l.split('-')[0] as Language);

      const languages: Language[] = _.uniq([
        ...ipDataLanguages,
        ...navigatorLanguages,
      ]).sort();

      return {
        languages: _.filter(
          data?.languages ?? languages,
          (l) => Object.values(Language).includes(l),
        ),
      };
    },
    [ipData?.languages, data],
  );

  const insets = useInsets();

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onComplete}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        isValidating,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          gap={2}
          h="100%"
          minH={0}
          noValidate
          onSubmit={(e) => {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
          pb={`max(${insets.bottom}, var(--chakra-space-2))`}
          pt={`max(${insets.top}, var(--chakra-space-2))`}
        >
          <Container>
            <HStack h={12} justifyContent="center">
              <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
            </HStack>
          </Container>

          <Container
            flex={1}
            minH={0}
            overflow="auto"
          >
            <VStack alignItems="stretch" flex={1} gap={8} overflow="auto">
              <CheckboxFormControl
                helperText={t('languages.helperText')}
                label={t('languages.label')}
                name="languages"
                options={_.reduce(Language, (r, k, l) => ({ ...r, [k]: l }), {})}
              />
            </VStack>
          </Container>

          <Container>
            <Button
              colorScheme={isValid ? undefined : 'red'}
              isLoading={isValidating || isSubmitting}
              type="submit"
              w="100%"
            >
              {isValid ? t('nextButton.default') : t('nextButton.invalid')}
            </Button>
          </Container>
        </VStack>
      )}
    </Formik>
  );
}

export default function LanguagesForm(props: Props) {
  return (
    <Suspense fallback={null}>
      <LanguagesFormMain
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Suspense>
  );
}
