import {
  Box,
  BoxProps,
  Heading,
  HStack,
  Icon,
  Image,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ProfileIndexRecord } from '@qupidu/hosting-common/src/common/collections/Profiles';
import Catch from '@qupidu/hosting-common/src/components/Catch';
import { useStorage } from '@qupidu/hosting-common/src/components/StorageProvider';
import useBlur from '@qupidu/hosting-common/src/hooks/useBlur';
import { getDownloadURL, ref } from 'firebase/storage';
import { compact } from 'lodash';
import mixpanel from 'mixpanel-browser';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { LuBadgeCheck } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';

export type Props = {
  profileRecord: ProfileIndexRecord;
} & BoxProps;

export function ProfileMain({
  profileRecord, ...boxProps
}: Props) {
  const navigate = useNavigate();
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      mixpanel.track('Profile Clicked');

      window.Telegram.WebApp.HapticFeedback.impactOccurred('soft');

      navigate(`/profiles/${profileRecord.objectID}`);
    },
    [navigate, profileRecord.objectID],
  );

  const storage = useStorage();

  const [src, setSrc] = useState<string | undefined>(undefined);

  useEffect(
    () => {
      getDownloadURL(ref(storage, profileRecord.pictures[0].phoneUri))
        .then((nextSrc) => {
          setSrc(nextSrc);
        })
        .catch(() => {
          setSrc(undefined);
        });
    },
    [profileRecord.pictures, storage],
  );

  const fallbackSrc = useBlur({
    blurHash: profileRecord.pictures[0].blurHash,
    height: 32,
    width: 18,
  });

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      borderRadius="lg"
      className="profileItem"
      h="100%"
      onClick={handleClick}
      overflow="hidden"
      position="relative"
    >
      <Image
        bottom={0}
        fallbackSrc={fallbackSrc}
        h="100%"
        left={0}
        objectFit="cover"
        position="absolute"
        right={0}
        src={src}
        top={0}
        w="100%"
        zIndex={-10}
      />

      <VStack
        alignItems="stretch"
        bottom={0}
        flexGrow={0}
        flexShrink={0}
        justifyContent="end"
        left={0}
        position="absolute"
        right={0}
        top={0}
      >
        <VStack
          alignItems="center"
          background="linear-gradient(0deg, var(--chakra-colors-chakra-body-bg) 0%, rgba(0, 0, 0, 0) 100%);"
          gap={1}
          py={5}
        >
          <HStack gap={1}>
            {profileRecord.isVerified ? <Icon as={LuBadgeCheck} boxSize={5} /> : null}

            <Heading
              as="h1"
              fontSize="xl"
              fontWeight="bold"
              textAlign="center"
            >
              {compact([profileRecord.name, profileRecord.age]).join(', ')}
            </Heading>
          </HStack>

          {profileRecord.origin ? (
            <Text
              fontSize="sm"
              opacity={0.75}
              textAlign="center"
            >
              {compact([
                profileRecord.origin.name,
                profileRecord.origin.country?.name,
              ]).join(', ')}
            </Text>
          ) : null}
        </VStack>
      </VStack>
    </Box>
  );
}

export function ProfileLoading({ ...boxProps }: BoxProps) {
  return (
    <Skeleton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      borderRadius="lg"
      h="100%"
    />
  );
}

export default function Profile({ profileRecord, ...boxProps }: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={(
        <ProfileLoading
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
        />
      )}
      >
        <ProfileMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
          profileRecord={profileRecord}
        />
      </Suspense>
    </Catch>
  );
}
