import base64URLdecode from '@qupidu/hosting-common/src/common/base64URLdecode';
import WebAppAction, { WebAppActionType } from '@qupidu/hosting-common/src/common/WebAppAction';
import mixpanel from 'mixpanel-browser';
import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

export default function ActionScreen() {
  const data = useMemo<undefined | WebAppAction>(
    () => {
      try {
        const params = new URLSearchParams(Telegram.WebApp.initData);
        const startParam = params.get('start_param');

        if (startParam) {
          const decoded = base64URLdecode(startParam);
          return JSON.parse(decoded) as WebAppAction;
        }

        return undefined;
      } catch (err) {
        return undefined;
      }
    },
    [],
  );

  switch (data?.action) {
    case WebAppActionType.CREATE_ARRANGEMENT: {
      mixpanel.track('Arrangement Creation Opened');
      return (
        <Navigate to="/arrangements/create" />
      );
    }
    case WebAppActionType.CREATE_JOB: {
      mixpanel.track('Job Creation Opened');
      return (
        <Navigate to="/jobs/create" />
      );
    }
    case WebAppActionType.CREATE_TRIP: {
      mixpanel.track('Trip Creation Opened');
      return (
        <Navigate to="/trips/create" />
      );
    }
    case WebAppActionType.CREATE_VENTURE: {
      mixpanel.track('Venture Creation Opened');
      return (
        <Navigate to="/ventures/create" />
      );
    }
    case WebAppActionType.LIST_ARRANGEMENTS: {
      mixpanel.track('Arrangements List Opened');
      return (
        <Navigate to="/arrangements" />
      );
    }
    case WebAppActionType.LIST_JOBS: {
      mixpanel.track('Jobs List Opened');
      return (
        <Navigate to="/jobs" />
      );
    }
    case WebAppActionType.LIST_KEYS: {
      mixpanel.track('Keys List Opened');
      return (
        <Navigate to="/keys" />
      );
    }
    case WebAppActionType.LIST_PROFILES: {
      mixpanel.track('Profiles List Opened');
      return (
        <Navigate to="/profiles" />
      );
    }
    case WebAppActionType.LIST_TRIPS: {
      mixpanel.track('Trips List Opened');
      return (
        <Navigate to="/trips" />
      );
    }
    case WebAppActionType.LIST_VENTURES: {
      mixpanel.track('Ventures List Opened');
      return (
        <Navigate to="/ventures" />
      );
    }
    case WebAppActionType.VIEW_ARRANGEMENT: {
      mixpanel.track('Arrangement View Opened', { arrangementId: data.arrangementId });
      return (
        <Navigate to={`/arrangements/${data.arrangementId}`} />
      );
    }
    case WebAppActionType.VIEW_ARRANGEMENT_APPLICATION: {
      mixpanel.track('Arrangement Application View Opened', {
        applicationId: data.applicationId,
        arrangementId: data.arrangementId,
      });
      return (
        <Navigate to={`/arrangements/${data.arrangementId}/applications/${data.applicationId}`} />
      );
    }
    case WebAppActionType.VIEW_JOB: {
      mixpanel.track('Job View Opened', { jobId: data.jobId });
      return (
        <Navigate to={`/jobs/${data.jobId}`} />
      );
    }
    case WebAppActionType.VIEW_JOB_APPLICATION: {
      mixpanel.track('Job Application View Opened', {
        applicationId: data.applicationId,
        jobId: data.jobId,
      });
      return (
        <Navigate to={`/jobs/${data.jobId}/applications/${data.applicationId}`} />
      );
    }
    case WebAppActionType.VIEW_KEY: {
      mixpanel.track('Key View Opened', { keyId: data.keyId });
      return (
        <Navigate to={`/keys/${data.keyId}`} />
      );
    }
    case WebAppActionType.VIEW_KEY_APPLICATION: {
      mixpanel.track('Key Application View Opened', {
        applicationId: data.applicationId,
        keyId: data.keyId,
      });
      return (
        <Navigate to={`/keys/${data.keyId}/applications/${data.applicationId}`} />
      );
    }
    case WebAppActionType.VIEW_PROFILE: {
      mixpanel.track('Profile View Opened', { profileId: data.profileId });
      return (
        <Navigate to={`/profiles/${data.profileId}`} />
      ); }
    case WebAppActionType.VIEW_TRIP: {
      mixpanel.track('Trip View Opened', { tripId: data.tripId });
      return (
        <Navigate to={`/trips/${data.tripId}`} />
      );
    }
    case WebAppActionType.VIEW_TRIP_APPLICATION: {
      mixpanel.track('Trip Application View Opened', {
        applicationId: data.applicationId,
        tripId: data.tripId,
      });
      return (
        <Navigate to={`/trips/${data.tripId}/applications/${data.applicationId}`} />
      );
    }
    case WebAppActionType.VIEW_VENTURE: {
      mixpanel.track('Venture View Opened', { ventureId: data.ventureId });
      return (
        <Navigate to={`/ventures/${data.ventureId}`} />
      );
    }
    case WebAppActionType.VIEW_VENTURE_APPLICATION: {
      mixpanel.track('Venture Application View Opened', {
        applicationId: data.applicationId,
        ventureId: data.ventureId,
      });
      return (
        <Navigate to={`/ventures/${data.ventureId}/applications/${data.applicationId}`} />
      );
    }
    case WebAppActionType.WELCOME: {
      mixpanel.track('Welcome View Opened');
      return (
        <Navigate to="/welcome" />
      );
    }
    default: {
      mixpanel.track('My Profile View Opened');
      return (
        <Navigate to="/trips" />
      );
    }
  }
}
