import {
  AspectRatio,
  Box,
  Center,
  Container,
  Grid,
  Text,
} from '@chakra-ui/react';
import {
  useVenturesCollectionRef,
  VentureStatus,
} from '@qupidu/hosting-common/src/common/collections/Ventures';
import Catch from '@qupidu/hosting-common/src/components/Catch';
import useQuerySnapshot from '@qupidu/hosting-common/src/hooks/useQuerySnapshot';
import { limit, query, where } from 'firebase/firestore';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Venture, { VentureLoading } from './Venture';

export function VentureGridLoading() {
  return (
    <Grid gap={2} gridAutoRows="1fr" templateColumns="repeat(2, 1fr)">
      <AspectRatio ratio={9 / 16}>
        <VentureLoading />
      </AspectRatio>
      <AspectRatio ratio={9 / 16}>
        <VentureLoading />
      </AspectRatio>
      <AspectRatio ratio={9 / 16}>
        <VentureLoading />
      </AspectRatio>
      <AspectRatio ratio={9 / 16}>
        <VentureLoading />
      </AspectRatio>
    </Grid>
  );
}

export function VentureGridMain() {
  const { t } = useTranslation('VenturesScreen', { keyPrefix: 'VentureList' });

  const venturesCollectionRef = useVenturesCollectionRef();
  const { snap: venturesSnap } = useQuerySnapshot(
    query(
      venturesCollectionRef,
      where('status', '==', VentureStatus.PUBLISHED),
      limit(100),
    ),
  );

  if (!venturesSnap) {
    return (
      <VentureGridLoading />
    );
  }

  if (venturesSnap.docs.length) {
    return (
      <Grid autoRows="1fr" gap={2} templateColumns="repeat(2, 1fr)">
        {venturesSnap.docs.map((ventureSnap) => (
          <AspectRatio
            key={ventureSnap.id}
            ratio={9 / 16}
          >
            <Venture
              scrollSnapAlign="center"
              ventureSnap={ventureSnap}
            />
          </AspectRatio>
        ))}

        {new Array(Math.ceil(venturesSnap.docs.length / 2) * 2 - venturesSnap.docs.length)
          .fill(null)
          .map((_, i) => (
            <Box
              _dark={{
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
              }}
              _light={{
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
              }}
              borderRadius="md"
              // eslint-disable-next-line react/no-array-index-key
              key={i}
            />
          ))}
      </Grid>
    );
  }

  return (
    <Container height="100%" maxW="lg">
      <Center height="100%">
        <Text textAlign="center">
          {t('emptyList.body')}
        </Text>
      </Center>
    </Container>
  );
}

export default function VentureGrid() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<VentureGridLoading />}>
        <VentureGridMain />
      </Suspense>
    </Catch>
  );
}
