import {
  Button,
  Container,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ProfileVersionDoc } from '@qupidu/hosting-common/src/common/collections/Profiles';
import EyeColor from '@qupidu/hosting-common/src/common/EyeColor';
import HairColor from '@qupidu/hosting-common/src/common/HairColor';
import HairLength from '@qupidu/hosting-common/src/common/HairLength';
import HairLengthFormControl from '@qupidu/hosting-common/src/components/HairLengthFormControl';
import { useInsets } from '@qupidu/hosting-common/src/components/InsetsProvider';
import LogoFull from '@qupidu/hosting-common/src/components/LogoFull';
import SelectFormControl from '@qupidu/hosting-common/src/components/SelectFormControl';
import { Formik } from 'formik';
import { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export type Props = {
  data: Pick<ProfileVersionDoc, 'eyeColor' | 'hairColor' | 'hairLength'> | undefined;
  onComplete: (data: Required<Pick<ProfileVersionDoc, 'eyeColor' | 'hairColor' | 'hairLength'>>) => void;
};

export function FaceFormMain({ data, onComplete }: Props) {
  const { t } = useTranslation('WelcomeScreen', { keyPrefix: 'ProfileForm' });
  const { t: ect } = useTranslation('EyeColor');
  const { t: hct } = useTranslation('HairColor');

  const schema = useMemo(
    () => yup.object().shape({
      eyeColor: yup
        .string()
        .label(t('eyeColor.label'))
        .oneOf(Object.values(EyeColor))
        .required(),
      hairColor: yup
        .string()
        .label(t('hairColor.label'))
        .oneOf(Object.values(HairColor))
        .required(),
      hairLength: yup
        .number()
        .label(t('hairLength.label'))
        .integer()
        .min(HairLength.BUZZ_CUT)
        .max(HairLength.TAILBONE)
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      eyeColor: data?.eyeColor ?? EyeColor.BLUE,
      hairColor: data?.hairColor ?? HairColor.WHITE,
      hairLength: data?.hairLength ?? HairLength.BUZZ_CUT,
    }),
    [data],
  );

  const insets = useInsets();

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onComplete}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        isValidating,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          gap={2}
          h="100%"
          minH={0}
          noValidate
          onSubmit={(e) => {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
          pb={`max(${insets.bottom}, var(--chakra-space-2))`}
          pt={`max(${insets.top}, var(--chakra-space-2))`}
        >
          <Container>
            <HStack h={12} justifyContent="center">
              <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
            </HStack>
          </Container>

          <Container
            flex={1}
            minH={0}
            overflow="auto"
          >
            <VStack alignItems="stretch" flex={1} gap={8} overflow="auto">
              <Grid columnGap={4} rowGap={2} templateColumns="repeat(2, 1fr)">
                <SelectFormControl
                  label={t('eyeColor.label')}
                  name="eyeColor"
                  options={{
                    /* eslint-disable perfectionist/sort-objects */
                    [EyeColor.BLUE]: ect('BLUE.label'),
                    [EyeColor.GRAY]: ect('GRAY.label'),
                    [EyeColor.GREEN]: ect('GREEN.label'),
                    [EyeColor.HAZEL]: ect('HAZEL.label'),
                    [EyeColor.AMBER]: ect('AMBER.label'),
                    [EyeColor.BROWN]: ect('BROWN.label'),
                    /* eslint-enable perfectionist/sort-objects */
                  }}
                />

                <SelectFormControl
                  label={t('hairColor.label')}
                  name="hairColor"
                  options={{
                    /* eslint-disable perfectionist/sort-objects */
                    [HairColor.WHITE]: hct('WHITE.label'),
                    [HairColor.GRAY]: hct('GRAY.label'),
                    [HairColor.BLONDE]: hct('BLONDE.label'),
                    [HairColor.CHESTNUT]: hct('CHESTNUT.label'),
                    [HairColor.BROWN]: hct('BROWN.label'),
                    [HairColor.RED]: hct('RED.label'),
                    [HairColor.BLACK]: hct('BLACK.label'),
                    [HairColor.OTHER]: hct('OTHER.label'),
                    /* eslint-enable perfectionist/sort-objects */
                  }}
                />

                <GridItem colSpan={2}>
                  <HairLengthFormControl
                    label={t('hairLength.label')}
                    name="hairLength"
                  />
                </GridItem>

                <GridItem colSpan={2}>
                  <Text
                    _dark={{ color: 'whiteAlpha.600' }}
                    fontSize="sm"
                  >
                    {t('eyesAndHair.helperText')}
                  </Text>
                </GridItem>
              </Grid>
            </VStack>
          </Container>

          <Container>
            <Button
              colorScheme={isValid ? undefined : 'red'}
              isLoading={isValidating || isSubmitting}
              type="submit"
              w="100%"
            >
              {isValid ? t('nextButton.default') : t('nextButton.invalid')}
            </Button>
          </Container>
        </VStack>
      )}
    </Formik>
  );
}

export default function FaceForm(props: Props) {
  return (
    <Suspense fallback={null}>
      <FaceFormMain
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Suspense>
  );
}
