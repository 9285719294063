import {
  AspectRatio,
  Box,
  BoxProps,
  Button,
  Center,
  Container,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Link,
  ListItem,
  Text,
  UnorderedList,
  useToast,
  VStack,
} from '@chakra-ui/react';
import AppLanguage from '@qupidu/hosting-common/src/common/AppLanguage';
import {
  ApplicationStatus,
  useApplicationsCollectionRef,
} from '@qupidu/hosting-common/src/common/collections/Applications';
import { ProfileDoc } from '@qupidu/hosting-common/src/common/collections/Profiles';
import { TripDoc, TripStatus } from '@qupidu/hosting-common/src/common/collections/Trips';
import ensureWriteAccess from '@qupidu/hosting-common/src/common/ensureWriteAccess';
import { useInsets } from '@qupidu/hosting-common/src/components/InsetsProvider';
import LogoFull from '@qupidu/hosting-common/src/components/LogoFull';
import LogoIcon from '@qupidu/hosting-common/src/components/LogoIcon';
import PictureCell from '@qupidu/hosting-common/src/components/PictureCell';
import PictureImage from '@qupidu/hosting-common/src/components/PictureImage';
import PreferencesTable from '@qupidu/hosting-common/src/components/PreferencesTable';
import {
  useMyProfileRef,
} from '@qupidu/hosting-common/src/components/refProviders/MyProfileRefProvider';
import useTelegramGetTripInvoiceUrl from '@qupidu/hosting-common/src/functions/useTelegramGetTripInvoiceUrl';
import useTelegramPrepareTripMessage from '@qupidu/hosting-common/src/functions/useTelegramPrepareTripMessage';
import useDocumentSnapshot from '@qupidu/hosting-common/src/hooks/useDocumentSnapshot';
import useShowError from '@qupidu/hosting-common/src/hooks/useShowError';
import useWindowDimensions from '@qupidu/hosting-common/src/hooks/useWindowDimensions';
import { animated, useScroll, useSpring } from '@react-spring/web';
import {
  doc,
  DocumentReference,
  getCountFromServer,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  refEqual,
  setDoc,
  Timestamp,
  where,
} from 'firebase/firestore';
import { clamp, compact } from 'lodash';
import mixpanel from 'mixpanel-browser';
import {
  CSSProperties,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosFemale, IoIosMale } from 'react-icons/io';
import { LuHeart, LuMapPin, LuSend } from 'react-icons/lu';
import Markdown, { Components } from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import remarkGfm from 'remark-gfm';

/* eslint-disable react/jsx-props-no-spreading */
const components: Components = {
  a: ({ node, ...props }) => (<Link isExternal {...props} />),
  h1: ({ node, ...props }) => (<Heading as="h1" {...props} />),
  h2: ({ node, ...props }) => (<Heading as="h2" {...props} />),
  h3: ({ node, ...props }) => (<Heading as="h3" {...props} />),
  li: ({ node, ...props }) => (<ListItem {...props} />),
  p: ({ node, ...props }) => (<Text as="p" {...props} />),
  strong: ({ node, ...props }) => (<Text as="strong" {...props} />),
  ul: ({ node, ...props }) => (<UnorderedList {...props} />),
};
/* eslint-enable react/jsx-props-no-spreading */

export type Props = {
  tripSnap: QueryDocumentSnapshot<TripDoc>;
} & BoxProps;

function useApplicationStatus(
  tripRef: DocumentReference<TripDoc>,
  profileRef: DocumentReference<ProfileDoc> | undefined,
): boolean {
  const [
    currentVentureRef,
    setCurrentVentureRef,
  ] = useState<DocumentReference<TripDoc>>(tripRef);

  const [
    currentProfileRef,
    setCurrentProfileRef,
  ] = useState<DocumentReference<ProfileDoc> | undefined>(profileRef);

  useEffect(
    () => {
      if (!refEqual(tripRef, currentVentureRef)) {
        setCurrentVentureRef(tripRef);
      }
    },
    [currentVentureRef, tripRef],
  );

  useEffect(
    () => {
      if (!profileRef) {
        setCurrentProfileRef(undefined);
      } else if (!currentProfileRef) {
        setCurrentProfileRef(profileRef);
      } else if (!refEqual(profileRef, currentProfileRef)) {
        setCurrentProfileRef(profileRef);
      }
    },
    [currentProfileRef, profileRef],
  );

  const [applicationExists, setApplicationExists] = useState<boolean>(false);

  const applicationsCollectionRef = useApplicationsCollectionRef();

  useEffect(
    () => {
      if (currentProfileRef) {
        return onSnapshot(
          query(
            applicationsCollectionRef,
            where('applicantRef', '==', currentProfileRef),
            where('subjectRef', '==', currentVentureRef),
          ),
          (snap) => {
            setApplicationExists(snap.docs.length > 0);
          },
        );
      }

      setApplicationExists(false);
      return () => { };
    },
    [applicationsCollectionRef, currentProfileRef, currentVentureRef],
  );

  return applicationExists;
}

export function TripRowMain({
  tripSnap, ...boxProps
}: Props) {
  const { i18n, t } = useTranslation('TripScreen', { keyPrefix: 'Trip' });

  useEffect(
    () => {
      window.Telegram.WebApp.disableVerticalSwipes();

      return () => {
        window.Telegram.WebApp.enableVerticalSwipes();
      };
    },
    [],
  );

  const myProfileRef = useMyProfileRef();

  const { snap: myProfileSnap } = useDocumentSnapshot(myProfileRef);

  const applicationsCollectionRef = useApplicationsCollectionRef();

  const [applicationsCount, setApplicationsCount] = useState<number>(0);
  useEffect(
    () => {
      getCountFromServer(
        query(
          applicationsCollectionRef,
          where('subjectRef', '==', tripSnap.ref),
        ),
      )
        .then((data) => setApplicationsCount(data.data().count))
        .catch(() => { });
    },
    [applicationsCollectionRef, tripSnap.ref],
  );

  const showError = useShowError();
  const navigate = useNavigate();
  const toast = useToast();
  const [isApplying, setIsApplying] = useState<boolean>(false);
  const onApplyClick = useCallback(
    () => {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');

      setIsApplying(true);

      (async () => {
        mixpanel.track('Trip Apply Clicked');

        if (!myProfileSnap?.exists()) {
          navigate('/welcome');
          return;
        }

        try {
          await ensureWriteAccess();
        } catch (err) {
          /* do nothing */
        }

        const tripDoc = tripSnap.data();

        const applicationRef = doc(applicationsCollectionRef);

        await setDoc(
          applicationRef,
          {
            _v: 1,
            applicantRef: myProfileRef,
            organizerRef: tripDoc.organizerRef,
            sentAt: Timestamp.now(),
            status: ApplicationStatus.SENT,
            subjectRef: tripSnap.ref,
          },
        );

        mixpanel.track('Trip Application Sent', {
          applicantId: myProfileRef.id,
          applicationId: applicationRef.id,
          organizerId: tripDoc.organizerRef.id,
          tripId: tripSnap.id,
        });

        toast({
          isClosable: true,
          status: 'success',
          title: t('applyButton.success'),
        });
      })()
        .finally(() => setIsApplying(false))
        .catch(showError);
    },
    [
      applicationsCollectionRef,
      myProfileRef,
      myProfileSnap,
      navigate,
      showError,
      t,
      toast,
      tripSnap,
    ],
  );

  const telegramGetTripInvoiceUrl = useTelegramGetTripInvoiceUrl();
  const [isPaying, setIsPaying] = useState<boolean>(false);
  const onPayClick = useCallback(
    () => {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');

      mixpanel.track('Trip Pay Clicked');

      setIsPaying(true);
      telegramGetTripInvoiceUrl({ tripId: tripSnap.id })
        .then(({ data: { url } }) => new Promise<'cancelled' | 'failed' | 'paid' | 'pending'>(
          (res) => { window.Telegram.WebApp.openInvoice(url, res); },
        ))
        .finally(() => setIsPaying(false))
        .catch(showError);
    },
    [showError, telegramGetTripInvoiceUrl, tripSnap.id],
  );

  const applicationExists = useApplicationStatus(tripSnap.ref, myProfileRef);

  const tripDoc = useMemo(() => tripSnap.data(), [tripSnap]);

  const { snap: citySnap } = useDocumentSnapshot(tripDoc.cityRef);
  const cityDoc = useMemo(() => citySnap?.data(), [citySnap]);

  const insets = useInsets();

  const { height: wh, width: ww } = useWindowDimensions();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const containerRef = useRef<HTMLDivElement>(null!);

  const [coverStyles, coverApi] = useSpring(() => ({
    y: 0,
  }));

  const [notchStyles, notchApi] = useSpring(() => ({
    height: 0,
  }));

  useScroll({
    container: containerRef,
    default: {
      immediate: true,
    },
    onChange: ({ value: { scrollY } }) => {
      coverApi.start({
        config: {
          clamp: true,
          friction: 20,
          tension: 300,
        },
        y: -(clamp(scrollY as number, 0, window.Telegram.WebApp.viewportHeight)) / 2,
      });

      notchApi.start({
        config: {
          clamp: true,
          friction: 20,
          tension: 300,
        },
        height: clamp(
          scrollY as number,
          window.Telegram.WebApp.viewportHeight / 4,
          window.Telegram.WebApp.viewportHeight / 4
          + Math.max(window.Telegram.WebApp.safeAreaInset.top, 8)
          + 48,
        ) - window.Telegram.WebApp.viewportHeight / 4,
      });
    },
  });

  const prepareTripMessage = useTelegramPrepareTripMessage();
  const [preparingTripMessage, setPreparingTripMessage] = useState<boolean>(false);
  const handleShare = useCallback(
    () => {
      if (!window.Telegram.WebApp.initDataUnsafe.user) {
        return;
      }

      setPreparingTripMessage(true);
      prepareTripMessage({
        language: i18n.language as AppLanguage,
        telegramUserId: window.Telegram.WebApp.initDataUnsafe.user.id,
        tripId: tripSnap.id,
      })
        .finally(() => { setPreparingTripMessage(false); })
        .then(({ data: { id } }) => { window.Telegram.WebApp.shareMessage(id); })
        .catch(showError);
    },
    [i18n.language, prepareTripMessage, showError, tripSnap.id],
  );

  return (
    <VStack
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      alignItems="stretch"
      gap={0}
      h="100%"
    >
      <Box
        minH={0}
        overflow="hidden"
        position="relative"
      >
        <HStack
          h={12}
          justifyContent="center"
          left={insets.left}
          position="absolute"
          right={insets.right}
          top={`max(${insets.top}, var(--chakra-space-2))`}
          zIndex={100}
        >
          <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
        </HStack>

        <Box
          as={animated.div}
          backgroundColor="var(--chakra-colors-chakra-body-bg)"
          left={0}
          position="absolute"
          right={0}
          style={notchStyles as unknown as CSSProperties}
          top={0}
          zIndex={90}
        />

        <PictureCell
          as={animated.div}
          height={wh}
          pictureRef={tripDoc.pictureRefs[0]}
          position="absolute"
          style={coverStyles as unknown as CSSProperties}
          width={ww}
          zIndex={-10}
        />

        <VStack
          alignItems="stretch"
          gap={0}
          h="100%"
          overflowY="auto"
          ref={containerRef}
        >
          <VStack
            alignItems="stretch"
            flexGrow={0}
            flexShrink={0}
            h="calc(var(--tg-viewport-height) - 100px)"
            justifyContent="end"
          >
            <VStack
              alignItems="center"
              background="linear-gradient(0deg, var(--chakra-colors-chakra-body-bg) 0%, rgba(0, 0, 0, 0) 100%);"
              gap={2}
              py={10}
            >
              <Container maxW="lg">
                <Heading
                  as="h1"
                  fontSize="3xl"
                  fontWeight="bold"
                  textAlign="center"
                >
                  {/* eslint-disable-next-line max-len */}
                  {(tripDoc.translations && tripDoc.translations[i18n.language as AppLanguage]?.name) ?? tripDoc.name}
                </Heading>
              </Container>

              <Text
                opacity={0.75}
              >
                {t('dates.value', {
                  endsAt: tripDoc.endsAt.toDate(),
                  formatParams: {
                    endsAt: { day: 'numeric', month: 'short', year: 'numeric' },
                    startsAt: { day: 'numeric', month: 'short' },
                  },
                  startsAt: tripDoc.startsAt.toDate(),
                })}
              </Text>

              <Button
                isLoading={preparingTripMessage}
                leftIcon={<Icon as={LuSend} />}
                loadingText={t('shareButton.loading')}
                onClick={handleShare}
                size="sm"
                variant="outline"
              >
                {t('shareButton.default')}
              </Button>
            </VStack>
          </VStack>

          <Box
            backgroundColor="var(--chakra-colors-chakra-body-bg)"
            flexGrow={0}
            flexShrink={0}
            pb={`max(${insets.bottom}, var(--chakra-space-2))`}
          >
            <Container maxW="lg">
              <VStack
                alignItems="stretch"
                gap={4}
              >
                <Markdown components={components} remarkPlugins={[remarkGfm]}>
                  {/* eslint-disable-next-line max-len */}
                  {(tripDoc.translations && tripDoc.translations[i18n.language as AppLanguage]?.description) ?? tripDoc.description}
                </Markdown>

                <Grid gap={1} templateColumns="repeat(2, auto)">
                  <HStack gap={1} justifySelf="start" maxW="100%" minW={0} opacity={0.75}>
                    <Icon as={LuMapPin} boxSize={3} />
                    <Text
                      fontSize="sm"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {compact([cityDoc?.name, cityDoc?.countryName]).join(', ')}
                    </Text>
                  </HStack>

                  <HStack gap={1} justifySelf="end" opacity={0.75}>
                    <Icon as={LuHeart} boxSize={3} />
                    <Text
                      fontSize="sm"
                      whiteSpace="nowrap"
                    >
                      {t('applicationsCount', { count: applicationsCount * 7 })}
                    </Text>
                  </HStack>
                </Grid>

                <Grid gap={2} templateColumns="repeat(2, 1fr)">
                  <GridItem
                    _dark={{
                      backgroundColor: 'rgb(from var(--chakra-colors-pink-200) r g b / 0.16)',
                      color: 'var(--chakra-colors-pink-200)',
                    }}
                    _light={{
                      backgroundColor: 'var(--chakra-colors-pink-100)',
                      color: 'var(--chakra-colors-pink-800)',
                    }}
                    borderRadius="md"
                    p={2}
                  >
                    <VStack
                      alignItems="stretch"
                      h="100%"
                    >
                      <HStack alignItems="center" justifyContent="space-between">
                        <Icon as={IoIosFemale} boxSize={5} />

                        <Text>
                          {tripDoc.femaleParticipantPlaces}
                        </Text>
                      </HStack>
                    </VStack>
                  </GridItem>

                  <GridItem
                    _dark={{
                      backgroundColor: 'rgb(from var(--chakra-colors-blue-200) r g b / 0.16)',
                      color: 'var(--chakra-colors-blue-200)',
                    }}
                    _light={{
                      backgroundColor: 'var(--chakra-colors-blue-100)',
                      color: 'var(--chakra-colors-blue-800)',
                    }}
                    borderRadius="md"
                    p={2}
                  >
                    <VStack
                      alignItems="stretch"
                      h="100%"
                    >
                      <HStack alignItems="center" justifyContent="space-between">
                        <Icon as={IoIosMale} boxSize={5} />

                        <Text>
                          {tripDoc.maleParticipantPlaces}
                        </Text>
                      </HStack>
                    </VStack>
                  </GridItem>
                </Grid>

                {tripDoc.pictureRefs.length ? (
                  <Grid
                    autoRows="1fr"
                    gap={2}
                    templateColumns="repeat(3, 1fr)"
                  >
                    {tripDoc.pictureRefs.map((pictureRef) => (
                      <GridItem key={pictureRef.id}>
                        <AspectRatio ratio={9 / 16}>
                          <PictureImage
                            borderRadius="md"
                            h="100%"
                            objectFit="cover"
                            pictureRef={pictureRef}
                          />
                        </AspectRatio>
                      </GridItem>
                    ))}

                    {new Array(
                      Math.ceil(
                        tripDoc.pictureRefs.length / 3,
                      ) * 3 - tripDoc.pictureRefs.length,
                    )
                      .fill(null)
                      .map((_, i) => (
                        <Box
                          _dark={{
                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                          }}
                          _light={{
                            backgroundColor: 'rgba(0, 0, 0, 0.05)',
                          }}
                          borderRadius="md"
                          // eslint-disable-next-line react/no-array-index-key
                          key={i}
                        />
                      ))}
                  </Grid>
                ) : null}

                <PreferencesTable
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  femaleProfilePreferences={tripDoc.femaleProfilePreferences}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  maleProfilePreferences={tripDoc.maleProfilePreferences}
                />
              </VStack>
            </Container>
          </Box>
        </VStack>
      </Box>

      <Container
        maxW="lg"
        pt={2}
      >
        {tripDoc.status === TripStatus.DRAFTED ? (
          <Button
            className="payButton"
            colorScheme="indigo"
            isLoading={isPaying}
            onClick={onPayClick}
            width="100%"
          >
            {t('payButton.default')}
          </Button>
        ) : null}

        {tripDoc.status === TripStatus.PUBLISHED ? (
          <Button
            className="applyButton"
            colorScheme="pink"
            isDisabled={applicationExists}
            isLoading={isApplying}
            onClick={onApplyClick}
            width="100%"
          >
            {t('applyButton.default')}
          </Button>
        ) : null}
      </Container>
    </VStack>
  );
}

export default function TripRow({ tripSnap, ...boxProps }: Props) {
  return (
    <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
      <TripRowMain
          // eslint-disable-next-line react/jsx-props-no-spreading
        {...boxProps}
        tripSnap={tripSnap}
      />
    </Suspense>
  );
}
