import {
  Button,
  Container,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ProfileVersionDoc } from '@qupidu/hosting-common/src/common/collections/Profiles';
import ensureWriteAccess from '@qupidu/hosting-common/src/common/ensureWriteAccess';
import { useInsets } from '@qupidu/hosting-common/src/components/InsetsProvider';
import LogoFull from '@qupidu/hosting-common/src/components/LogoFull';
import useShowError from '@qupidu/hosting-common/src/hooks/useShowError';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export type Props = {
  lastProfileVersionSnap: QueryDocumentSnapshot<ProfileVersionDoc>;
};

export default function ProfileVersionReceived({ lastProfileVersionSnap }: Props) {
  const { t } = useTranslation('WelcomeScreen', { keyPrefix: 'ProfileVersionReceived' });

  const lastProfileVersionDoc = useMemo(
    () => lastProfileVersionSnap.data(),
    [lastProfileVersionSnap],
  );

  const insets = useInsets();

  const [isEnabled, setEnabled] = useState(
    window.Telegram.WebApp.initDataUnsafe.user?.allows_write_to_pm === true,
  );

  const [isEnabling, setEnabling] = useState(false);
  const showError = useShowError();
  const enableNotificationsClick = useCallback(
    () => {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
      setEnabling(true);

      ensureWriteAccess()
        .finally(() => setEnabling(false))
        .then(() => setEnabled(true))
        .catch(showError);
    },
    [showError],
  );

  return (
    <VStack
      alignItems="stretch"
      gap={2}
      h="100%"
      pb={`max(${insets.bottom}, var(--chakra-space-2))`}
      pt={`max(${insets.top}, var(--chakra-space-2))`}
    >
      <Container>
        <HStack h={12} justifyContent="center">
          <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
        </HStack>
      </Container>

      <Container
        flex={1}
        minH={0}
        overflow="auto"
      >
        <VStack
          alignItems="stretch"
          gap={4}
          h="100%"
          justifyContent="center"
        >
          <Text fontSize="xl" fontWeight="bold" textAlign="center">
            {t('thankYou', { name: lastProfileVersionDoc.name })}
          </Text>

          <Text textAlign="center">
            {t('confirmation')}
          </Text>

          <Text fontSize="sm" textAlign="center">
            <Trans
              components={[
                <Text as="a" fontWeight="bold" href={`https://instagram.com/${lastProfileVersionDoc.instagramTag}`} target="_blank">{`@${lastProfileVersionDoc.instagramTag}`}</Text>,
                <Text as="a" fontWeight="bold" href="https://instagram.com/qupidu.travel" target="_blank">@qupidu.travel</Text>,
              ]}
              i18nKey="process"
              t={t}
            />
          </Text>
        </VStack>
      </Container>

      {!isEnabled ? (
        <Container>
          <Button
            isLoading={isEnabling}
            onClick={enableNotificationsClick}
            w="100%"
          >
            {t('enableNotificationsButton.default')}
          </Button>
        </Container>
      ) : null}
    </VStack>
  );
}
