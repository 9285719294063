import {
  Center,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useJobsCollectionRef } from '@qupidu/hosting-common/src/common/collections/Jobs';
import Catch from '@qupidu/hosting-common/src/components/Catch';
import LogoIcon from '@qupidu/hosting-common/src/components/LogoIcon';
import useDocumentSnapshot from '@qupidu/hosting-common/src/hooks/useDocumentSnapshot';
import { doc } from 'firebase/firestore';
import { Suspense, useEffect } from 'react';
import { LuAlertTriangle } from 'react-icons/lu';
import { useNavigate, useParams } from 'react-router-dom';

import ErrorFallbackScreen from '../ErrorFallbackScreen';
import JobRow from './JobRow';

export function JobsScreenMain() {
  const navigate = useNavigate();
  useEffect(
    () => {
      const onClick = () => {
        window.Telegram.WebApp.HapticFeedback.impactOccurred('soft');

        navigate('..');
      };

      window.Telegram.WebApp.BackButton.onClick(onClick);
      window.Telegram.WebApp.BackButton.show();

      return () => {
        window.Telegram.WebApp.BackButton.hide();
        window.Telegram.WebApp.BackButton.offClick(onClick);
      };
    },
    [navigate],
  );

  const { jobId } = useParams<'jobId'>();

  const jobsCollectionRef = useJobsCollectionRef();
  const { snap: jobSnap } = useDocumentSnapshot(
    doc(
      jobsCollectionRef,
      jobId,
    ),
  );

  if (!jobSnap || (!jobSnap.exists() && jobSnap.metadata.fromCache)) {
    return (
      <Center h="100%">
        <LogoIcon boxSize={16} />
      </Center>
    );
  }

  if (!jobSnap.exists()) {
    return (
      <Center h="100%">
        <VStack>
          <Icon as={LuAlertTriangle} boxSize={10} />
          <Text>Job not found</Text>
        </VStack>
      </Center>
    );
  }

  return (
    <JobRow jobSnap={jobSnap} />
  );
}

export default function JobsScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <JobsScreenMain />
      </Suspense>
    </Catch>
  );
}
