/* eslint-disable react/require-default-props */
import {
  Box,
  BoxProps,
  Container,
  Table,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import _, { isNil } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { bodyCountLabels } from '../../common/BodyCount';
import { ProfileDoc } from '../../common/collections/Profiles';
import { cupSizeLabels } from '../../common/CupSize';
import { hairLengthLabels } from '../../common/HairLength';
import { pantiesSizeLabels } from '../../common/PantiesSize';
import { wealthLabels } from '../../common/Wealth';
import useDocumentSnapshot from '../../hooks/useDocumentSnapshot';

export type Props = {
  profileSnap: QueryDocumentSnapshot<ProfileDoc>;
} & BoxProps;

export default function InfoCell({
  profileSnap,
  ...boxProps
}: Props) {
  const { t: lt } = useTranslation('Languages');
  const { t: zt } = useTranslation('Zodiac');
  const { t: cst } = useTranslation('CupSize');
  const { t: pst } = useTranslation('PantiesSize');
  const { t: ect } = useTranslation('EyeColor');
  const { t: hct } = useTranslation('HairColor');
  const { t: hlt } = useTranslation('HairLength');
  const { t: rst } = useTranslation('RelationshipStyle');
  const { t: gt } = useTranslation('Goal');
  const { t: st } = useTranslation('Sexuality');
  const { t: bct } = useTranslation('BodyCount');
  const { t: wt } = useTranslation('Wealth');

  const applicantDoc = useMemo(
    () => profileSnap.data(),
    [profileSnap],
  );

  const { snap: originSnap } = useDocumentSnapshot(applicantDoc.originRef);

  const originDoc = useMemo(
    () => originSnap?.data(),
    [originSnap],
  );

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      className="tripInfo"
    >
      <Container
        h="100%"
        maxW="lg"
        mb="calc(max(env(safe-area-inset-bottom), var(--chakra-space-4)) + var(--chakra-space-12))"
        mt="calc(max(env(safe-area-inset-top), var(--chakra-space-4)) + var(--chakra-space-12))"
        overflow="auto"
        py={4}
      >
        <Table size="sm" variant="simple">
          <Tbody>
            {!isNil(applicantDoc.name) ? (
              <Tr>
                <Td>
                  Name
                </Td>
                <Td>
                  {applicantDoc.name}
                </Td>
              </Tr>
            ) : null}

            {!isNil(applicantDoc.age) ? (
              <Tr>
                <Td>
                  Age
                </Td>
                <Td>
                  {applicantDoc.age}
                  y
                  {' '}
                  {!isNil(applicantDoc.zodiac) ? `(${zt(applicantDoc.zodiac)})` : null}
                </Td>
              </Tr>
            ) : null}

            {!isNil(applicantDoc.cupSize) && !isNil(applicantDoc.pantiesSize) ? (
              <Tr>
                <Td>
                  Bikini
                </Td>
                <Td>
                  {!isNil(applicantDoc.cupSize) ? cst(`${cupSizeLabels[applicantDoc.cupSize]}.label`) : '-'}
                  {' / '}
                  {!isNil(applicantDoc.pantiesSize) ? pst(`${pantiesSizeLabels[applicantDoc.pantiesSize]}.label`) : '-'}
                </Td>
              </Tr>
            ) : null}

            {!isNil(applicantDoc.height) ? (
              <Tr>
                <Td>
                  Height
                </Td>
                <Td>
                  {applicantDoc.height}
                  cm
                </Td>
              </Tr>
            ) : null}

            {!isNil(applicantDoc.weight) ? (
              <Tr>
                <Td>
                  Weight
                </Td>
                <Td>
                  {applicantDoc.weight}
                  kg
                </Td>
              </Tr>
            ) : null}

            {!isNil(applicantDoc.eyeColor) ? (
              <Tr>
                <Td>
                  Eyes
                </Td>
                <Td>
                  {!isNil(applicantDoc.eyeColor) ? ect(`${applicantDoc.eyeColor}.label`) : '-'}
                </Td>
              </Tr>
            ) : null}

            {!isNil(applicantDoc.hairColor) && !isNil(applicantDoc.hairLength) ? (
              <Tr>
                <Td>
                  Hair
                </Td>
                <Td>
                  {!isNil(applicantDoc.hairColor) ? hct(`${applicantDoc.hairColor}.label`) : '-'}
                  {' / '}
                  {!isNil(applicantDoc.hairLength) ? hlt(`${hairLengthLabels[applicantDoc.hairLength]}.label`) : '-'}
                </Td>
              </Tr>
            ) : null}

            {!isNil(applicantDoc.wealth) ? (
              <Tr>
                <Td>
                  Wealth
                </Td>
                <Td>
                  {!isNil(applicantDoc.wealth) ? wt(`${wealthLabels[applicantDoc.wealth]}.label`) : '-'}
                </Td>
              </Tr>
            ) : null}

            {applicantDoc.languages?.length ? (
              <Tr>
                <Td>
                  Languages
                </Td>
                <Td whiteSpace="normal">
                  {applicantDoc.languages.map((l) => lt(l)).join(', ')}
                </Td>
              </Tr>
            ) : null}

            {applicantDoc.goals?.length ? (
              <Tr>
                <Td>
                  Goals
                </Td>
                <Td>
                  {(applicantDoc.goals ?? []).map((r) => gt(`${r}.label`)).join(', ')}
                </Td>
              </Tr>
            ) : null}

            {applicantDoc.relationshipStyles?.length ? (
              <Tr>
                <Td>
                  Relationship Style
                </Td>
                <Td>
                  {(applicantDoc.relationshipStyles ?? []).map((r) => rst(`${r}.label`)).join(', ')}
                </Td>
              </Tr>
            ) : null}

            {!isNil(applicantDoc.sexuality) ? (
              <Tr>
                <Td>
                  Sexuality
                </Td>
                <Td>
                  {applicantDoc.sexuality ? st(`${applicantDoc.sexuality}.label`) : '-'}
                </Td>
              </Tr>
            ) : null}

            {!isNil(applicantDoc.bodyCount) ? (
              <Tr>
                <Td>
                  {bct('label')}
                </Td>
                <Td>
                  {bct(`${bodyCountLabels[applicantDoc.bodyCount]}.label`)}
                </Td>
              </Tr>
            ) : null}

            {!isNil(applicantDoc.childrenCount) ? (
              <Tr>
                <Td>
                  Kids
                </Td>
                <Td>
                  {applicantDoc.childrenCount}
                </Td>
              </Tr>
            ) : null}

            {originDoc ? (
              <Tr>
                <Td>
                  Origin
                </Td>
                <Td whiteSpace="normal">
                  {_.compact([
                    originDoc.name,
                    originDoc.countryName,
                  ]).join(', ')}
                </Td>
              </Tr>
            ) : null}

            {!isNil(applicantDoc.description) ? (
              <Tr>
                <Td colSpan={2}>
                  {applicantDoc.description}
                </Td>
              </Tr>
            ) : null}
          </Tbody>
        </Table>
      </Container>
    </Box>
  );
}
