import { Helmet } from 'react-helmet';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import ActionScreen from './screens/ActionScreen';
import ApplicationScreen from './screens/ApplicationScreen';
import ArrangementCreateScreen from './screens/ArrangementCreateScreen';
import ArrangementScreen from './screens/ArrangementScreen';
import ArrangementsScreen from './screens/ArrangementsScreen';
import CheckMyProfileRefLayout from './screens/CheckMyProfileRefLayout';
import CheckMyProfileSnapLayout from './screens/CheckMyProfileSnapLayout';
import JobCreateScreen from './screens/JobCreateScreen';
import JobScreen from './screens/JobScreen';
import JobsScreen from './screens/JobsScreen';
import MainLayout from './screens/MainLayout';
import MyProfileScreen from './screens/MyProfileScreen';
import MyProfileUpdateScreen from './screens/MyProfileUpdateScreen';
import ProfileScreen from './screens/ProfileScreen';
import ProfilesScreen from './screens/ProfilesScreen';
import TelegramLoginScreen from './screens/TelegramLoginScreen';
import TripCreateScreen from './screens/TripCreateScreen';
import TripScreen from './screens/TripScreen';
import TripsScreen from './screens/TripsScreen';
import VentureCreateScreen from './screens/VentureCreateScreen';
import VentureScreen from './screens/VentureScreen';
import VenturesScreen from './screens/VenturesScreen';
import WelcomeScreen from './screens/WelcomeScreen';

export default function Navigation() {
  return (
    <>
      <Helmet defaultTitle="Qupidu - Exclusive Travel Community" titleTemplate="%s | Qupidu" />

      <BrowserRouter>
        <Routes>
          <Route element={<ActionScreen />} index />
          <Route element={<TelegramLoginScreen />} path="login" />

          <Route element={<CheckMyProfileRefLayout fallback={<Navigate replace to="/login" />} />}>
            <Route element={<WelcomeScreen />} path="welcome" />

            <Route element={<MainLayout />}>
              <Route path="trips">
                <Route element={<TripsScreen />} index />
                <Route element={<TripCreateScreen />} path="create" />

                <Route path=":tripId">
                  <Route element={<TripScreen />} index />

                  <Route path="applications">
                    <Route element={<Navigate replace to=".." />} index />
                    <Route element={<ApplicationScreen />} path=":applicationId" />
                  </Route>
                </Route>
              </Route>

              <Route path="ventures">
                <Route element={<VenturesScreen />} index />
                <Route element={<VentureCreateScreen />} path="create" />

                <Route path=":ventureId">
                  <Route element={<VentureScreen />} index />

                  <Route path="applications">
                    <Route element={<Navigate replace to=".." />} index />
                    <Route element={<ApplicationScreen />} path=":applicationId" />
                  </Route>
                </Route>
              </Route>

              <Route path="arrangements">
                <Route element={<ArrangementsScreen />} index />
                <Route element={<ArrangementCreateScreen />} path="create" />

                <Route path=":arrangementId">
                  <Route element={<ArrangementScreen />} index />

                  <Route path="applications">
                    <Route element={<Navigate replace to=".." />} index />
                    <Route element={<ApplicationScreen />} path=":applicationId" />
                  </Route>
                </Route>
              </Route>

              <Route path="jobs">
                <Route element={<JobsScreen />} index />
                <Route element={<JobCreateScreen />} path="create" />

                <Route path=":jobId">
                  <Route element={<JobScreen />} index />

                  <Route path="applications">
                    <Route element={<Navigate replace to=".." />} index />
                    <Route element={<ApplicationScreen />} path=":applicationId" />
                  </Route>
                </Route>
              </Route>

              <Route path="profiles">
                <Route element={<ProfilesScreen />} index />
                <Route element={<ProfileScreen />} path=":profileId" />
              </Route>

              <Route element={<ApplicationScreen />} path="applications/:applicationId" />

              <Route path="profile">
                <Route element={<MyProfileScreen />} index />

                <Route element={<CheckMyProfileSnapLayout fallback={<Navigate replace to="/welcome" />} />}>
                  <Route element={<MyProfileUpdateScreen />} path="update" />
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
