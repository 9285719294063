import {
  Button,
  Container,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import BodyCount from '@qupidu/hosting-common/src/common/BodyCount';
import { ProfileVersionDoc } from '@qupidu/hosting-common/src/common/collections/Profiles';
import BodyCountFormControl from '@qupidu/hosting-common/src/components/BodyCountFormControl';
import { useInsets } from '@qupidu/hosting-common/src/components/InsetsProvider';
import LogoFull from '@qupidu/hosting-common/src/components/LogoFull';
import NumberFormControl from '@qupidu/hosting-common/src/components/NumberFormControl';
import { Formik } from 'formik';
import { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export type Props = {
  data: Pick<ProfileVersionDoc, 'bodyCount' | 'childrenCount'> | undefined;
  onComplete: (data: Required<Pick<ProfileVersionDoc, 'bodyCount' | 'childrenCount'>>) => void;
};

export function MileageFormMain({ data, onComplete }: Props) {
  const { t } = useTranslation('WelcomeScreen', { keyPrefix: 'ProfileForm' });

  const schema = useMemo(
    () => yup.object().shape({
      bodyCount: yup
        .number()
        .label(t('bodyCount.label'))
        .integer()
        .min(BodyCount.VIRGIN)
        .max(BodyCount.THOUSANDS)
        .required(),
      childrenCount: yup
        .number()
        .label(t('childrenCount.label'))
        .integer()
        .min(0)
        .max(20)
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      bodyCount: data?.bodyCount ?? BodyCount.THOUSANDS,
      childrenCount: data?.childrenCount ?? 10,
    }),
    [data],
  );

  const insets = useInsets();

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onComplete}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        isValidating,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          gap={2}
          h="100%"
          minH={0}
          noValidate
          onSubmit={(e) => {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
          pb={`max(${insets.bottom}, var(--chakra-space-2))`}
          pt={`max(${insets.top}, var(--chakra-space-2))`}
        >
          <Container>
            <HStack h={12} justifyContent="center">
              <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
            </HStack>
          </Container>

          <Container
            flex={1}
            minH={0}
            overflow="auto"
          >
            <VStack alignItems="stretch" flex={1} gap={8} overflow="auto">
              <BodyCountFormControl
                label={t('bodyCount.label')}
                name="bodyCount"
              />

              <NumberFormControl
                label={t('childrenCount.label')}
                max={10}
                min={0}
                name="childrenCount"
                step={1}
              />

              <Text
                _dark={{ color: 'whiteAlpha.600' }}
                fontSize="sm"
              >
                {t('mileage.helperText')}
              </Text>
            </VStack>
          </Container>

          <Container>
            <Button
              colorScheme={isValid ? undefined : 'red'}
              isLoading={isValidating || isSubmitting}
              type="submit"
              w="100%"
            >
              {isValid ? t('nextButton.default') : t('nextButton.invalid')}
            </Button>
          </Container>
        </VStack>
      )}
    </Formik>
  );
}

export default function MileageForm(props: Props) {
  return (
    <Suspense fallback={null}>
      <MileageFormMain
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Suspense>
  );
}
