import {
  Box,
  BoxProps,
  Container,
  Heading,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import AppLanguage from '@qupidu/hosting-common/src/common/AppLanguage';
import { ArrangementDoc } from '@qupidu/hosting-common/src/common/collections/Arrangements';
import Catch from '@qupidu/hosting-common/src/components/Catch';
import PictureCell from '@qupidu/hosting-common/src/components/PictureCell';
import useDimensions from '@qupidu/hosting-common/src/hooks/useDimensions';
import useDocumentSnapshot from '@qupidu/hosting-common/src/hooks/useDocumentSnapshot';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { compact } from 'lodash';
import mixpanel from 'mixpanel-browser';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export type Props = {
  arrangementSnap: QueryDocumentSnapshot<ArrangementDoc>;
} & BoxProps;

export function ArrangementMain({
  arrangementSnap, ...boxProps
}: Props) {
  const { i18n } = useTranslation();

  const navigate = useNavigate();
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      mixpanel.track('Arrangement Clicked');

      window.Telegram.WebApp.HapticFeedback.impactOccurred('soft');

      navigate(`/arrangements/${arrangementSnap.id}`);
    },
    [navigate, arrangementSnap.id],
  );

  const arrangementDoc = useMemo(() => arrangementSnap.data(), [arrangementSnap]);

  const { snap: citySnap } = useDocumentSnapshot(arrangementDoc.cityRef);

  const cityDoc = useMemo(
    () => citySnap?.data(),
    [citySnap],
  );

  const [ref, { height: h, width: w }] = useDimensions();

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      borderRadius="lg"
      className="arrangementItem"
      h="100%"
      onClick={handleClick}
      overflow="hidden"
      position="relative"
      ref={ref}
    >
      <PictureCell
        bottom={0}
        height={h ?? 1600}
        left={0}
        pictureRef={arrangementDoc.pictureRefs[0]}
        position="absolute"
        right={0}
        top={0}
        width={w ?? 900}
        zIndex={-10}
      />

      <VStack
        alignItems="stretch"
        bottom={0}
        flexGrow={0}
        flexShrink={0}
        justifyContent="end"
        left={0}
        position="absolute"
        right={0}
        top={0}
      >
        <VStack
          alignItems="center"
          background="linear-gradient(0deg, var(--chakra-colors-chakra-body-bg) 0%, rgba(0, 0, 0, 0) 100%);"
          gap={1}
          py={5}
        >
          <Container maxW="lg">
            <Heading
              as="h1"
              fontSize="xl"
              fontWeight="bold"
              textAlign="center"
            >
              {/* eslint-disable-next-line max-len */}
              {(arrangementDoc.translations && arrangementDoc.translations[i18n.language as AppLanguage]?.name) ?? arrangementDoc.name}
            </Heading>
          </Container>

          {cityDoc ? (
            <Text
              fontSize="sm"
              opacity={0.75}
              textAlign="center"
            >
              {compact([
                cityDoc.name,
                cityDoc.countryName,
              ]).join(', ')}
            </Text>
          ) : null}
        </VStack>
      </VStack>
    </Box>
  );
}

export function ArrangementLoading({ ...boxProps }: BoxProps) {
  return (
    <Skeleton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      borderRadius="lg"
      h="100%"
    />
  );
}

export default function Arrangement({ arrangementSnap, ...boxProps }: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={(
        <ArrangementLoading
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
        />
      )}
      >
        <ArrangementMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
          arrangementSnap={arrangementSnap}
        />
      </Suspense>
    </Catch>
  );
}
