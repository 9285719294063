import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import { bodyCountLabels } from '../common/BodyCount';
import { cupSizeLabels } from '../common/CupSize';
import { pantiesSizeLabels } from '../common/PantiesSize';
import Preferences from '../common/Preferences';

export type Props = {
  femaleProfilePreferences: Preferences | undefined;
  maleProfilePreferences: Preferences | undefined;
};

export function PreferencesTableMain({
  femaleProfilePreferences,
  maleProfilePreferences,
}: Props) {
  const { t: cst } = useTranslation('CupSize');
  const { t: bct } = useTranslation('BodyCount');
  const { t: pst } = useTranslation('PantiesSize');
  const { t: rst } = useTranslation('RelationshipStyle');
  const { t: st } = useTranslation('Sexuality');
  const { t: lt } = useTranslation('Languages');

  if (!femaleProfilePreferences && !maleProfilePreferences) {
    return null;
  }

  return (
    <Table size="sm" variant="simple">
      <Thead>
        <Th>
          Preference
        </Th>

        {femaleProfilePreferences ? (
          <Th>
            Females
          </Th>
        ) : null}

        {maleProfilePreferences ? (
          <Th>
            Males
          </Th>
        ) : null}
      </Thead>

      <Tbody>
        {femaleProfilePreferences?.age || maleProfilePreferences?.age
          ? (
            <Tr>
              <Th>
                Age
              </Th>

              {femaleProfilePreferences ? (
                <Td>
                  {femaleProfilePreferences?.age ? (
                    <>
                      {femaleProfilePreferences.age.min}
                      {' - '}
                      {femaleProfilePreferences.age.max}
                      y
                    </>
                  ) : null}
                </Td>
              ) : null}

              {maleProfilePreferences ? (
                <Td>
                  {maleProfilePreferences?.age ? (
                    <>
                      {maleProfilePreferences.age.min}
                      {' - '}
                      {maleProfilePreferences.age.max}
                      y
                    </>
                  ) : null}
                </Td>
              ) : null}
            </Tr>
          )
          : null}

        {femaleProfilePreferences?.cupSize
          ? (
            <Tr>
              <Th>
                {cst('label')}
              </Th>

              {femaleProfilePreferences ? (
                <Td>
                  {femaleProfilePreferences?.cupSize ? (
                    <>
                      {cst(`${cupSizeLabels[femaleProfilePreferences.cupSize.min]}.label`)}
                      {' - '}
                      {cst(`${cupSizeLabels[femaleProfilePreferences.cupSize.max]}.label`)}
                    </>
                  ) : null}
                </Td>
              ) : null}

              {maleProfilePreferences ? (
                <Td />
              ) : null}
            </Tr>
          )
          : null}

        {femaleProfilePreferences?.pantiesSize
          ? (
            <Tr>
              <Th>
                {pst('label')}
              </Th>

              {femaleProfilePreferences ? (
                <Td>
                  {femaleProfilePreferences?.pantiesSize ? (
                    <>
                      {pst(`${pantiesSizeLabels[femaleProfilePreferences.pantiesSize.min]}.label`)}
                      {' - '}
                      {pst(`${pantiesSizeLabels[femaleProfilePreferences.pantiesSize.max]}.label`)}
                    </>
                  ) : null}
                </Td>
              ) : null}

              {maleProfilePreferences ? (
                <Td />
              ) : null}
            </Tr>
          )
          : null}

        {femaleProfilePreferences?.height || maleProfilePreferences?.height
          ? (
            <Tr>
              <Th>
                Height
              </Th>

              {femaleProfilePreferences ? (
                <Td>
                  {femaleProfilePreferences?.height ? (
                    <>
                      {femaleProfilePreferences.height.min}
                      {' - '}
                      {femaleProfilePreferences.height.max}
                      cm
                    </>
                  ) : null}
                </Td>
              ) : null}

              {maleProfilePreferences ? (
                <Td>
                  {maleProfilePreferences?.height ? (
                    <>
                      {maleProfilePreferences.height.min}
                      {' - '}
                      {maleProfilePreferences.height.max}
                      cm
                    </>
                  ) : null}
                </Td>
              ) : null}
            </Tr>
          )
          : null}

        {femaleProfilePreferences?.weight || maleProfilePreferences?.weight
          ? (
            <Tr>
              <Th>
                Weight
              </Th>

              {femaleProfilePreferences ? (
                <Td>
                  {femaleProfilePreferences?.weight ? (
                    <>
                      {femaleProfilePreferences.weight.min}
                      {' - '}
                      {femaleProfilePreferences.weight.max}
                      kg
                    </>
                  ) : null}
                </Td>
              ) : null}

              {maleProfilePreferences ? (
                <Td>
                  {maleProfilePreferences?.weight ? (
                    <>
                      {maleProfilePreferences.weight.min}
                      {' - '}
                      {maleProfilePreferences.weight.max}
                      kg
                    </>
                  ) : null}
                </Td>
              ) : null}
            </Tr>
          )
          : null}

        {femaleProfilePreferences?.bodyCount || maleProfilePreferences?.bodyCount
          ? (
            <Tr>
              <Th>
                {bct('label')}
              </Th>

              {femaleProfilePreferences ? (
                <Td>
                  {femaleProfilePreferences?.bodyCount ? (
                    <>
                      {bct(`${bodyCountLabels[femaleProfilePreferences.bodyCount.min]}.label`)}
                      {' - '}
                      {bct(`${bodyCountLabels[femaleProfilePreferences.bodyCount.max]}.label`)}
                    </>
                  ) : null}
                </Td>
              ) : null}

              {maleProfilePreferences ? (
                <Td>
                  {maleProfilePreferences?.bodyCount ? (
                    <>
                      {bct(`${bodyCountLabels[maleProfilePreferences.bodyCount.min]}.label`)}
                      {' - '}
                      {bct(`${bodyCountLabels[maleProfilePreferences.bodyCount.max]}.label`)}
                    </>
                  ) : null}
                </Td>
              ) : null}
            </Tr>
          )
          : null}

        {femaleProfilePreferences?.sexualities || maleProfilePreferences?.sexualities
          ? (
            <Tr>
              <Th>
                {st('label')}
              </Th>

              {femaleProfilePreferences ? (
                <Td>
                  {femaleProfilePreferences?.sexualities ? (
                    <>
                      {femaleProfilePreferences.sexualities.map((sexuality) => st(`${sexuality}.label`)).join(', ')}
                    </>
                  ) : null}
                </Td>
              ) : null}

              {maleProfilePreferences ? (
                <Td>
                  {maleProfilePreferences?.sexualities ? (
                    <>
                      {maleProfilePreferences.sexualities.map((sexuality) => st(`${sexuality}.label`)).join(', ')}
                    </>
                  ) : null}
                </Td>
              ) : null}
            </Tr>
          )
          : null}

        {femaleProfilePreferences?.relationshipStyles || maleProfilePreferences?.relationshipStyles
          ? (
            <Tr>
              <Th>
                {rst('label')}
              </Th>

              {femaleProfilePreferences ? (
                <Td>
                  {femaleProfilePreferences?.relationshipStyles ? (
                    <>
                      {femaleProfilePreferences.relationshipStyles.map((relationshipStyle) => rst(`${relationshipStyle}.label`)).join(', ')}
                    </>
                  ) : null}
                </Td>
              ) : null}

              {maleProfilePreferences ? (
                <Td>
                  {maleProfilePreferences?.relationshipStyles ? (
                    <>
                      {maleProfilePreferences.relationshipStyles.map((relationshipStyle) => rst(`${relationshipStyle}.label`)).join(', ')}
                    </>
                  ) : null}
                </Td>
              ) : null}
            </Tr>
          )
          : null}

        {femaleProfilePreferences?.languages || maleProfilePreferences?.languages
          ? (
            <Tr>
              <Th>
                {lt('label')}
              </Th>

              {femaleProfilePreferences ? (
                <Td>
                  {femaleProfilePreferences?.languages ? (
                    <>
                      {femaleProfilePreferences.languages.map((language) => lt(language)).join(', ')}
                    </>
                  ) : null}
                </Td>
              ) : null}

              {maleProfilePreferences ? (
                <Td>
                  {maleProfilePreferences?.languages ? (
                    <>
                      {maleProfilePreferences.languages.map((language) => lt(language)).join(', ')}
                    </>
                  ) : null}
                </Td>
              ) : null}
            </Tr>
          )
          : null}
      </Tbody>
    </Table>
  );
}

export default function PreferencesTable(props: Props) {
  return (
    <Suspense fallback={null}>
      <PreferencesTableMain
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Suspense>
  );
}
