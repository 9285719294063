import {
  Button,
  Container,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  ProfileVersionDoc,
  ProfileVersionRejectionReason,
} from '@qupidu/hosting-common/src/common/collections/Profiles';
import { useInsets } from '@qupidu/hosting-common/src/components/InsetsProvider';
import LogoFull from '@qupidu/hosting-common/src/components/LogoFull';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type Props = {
  lastProfileVersionSnap: QueryDocumentSnapshot<ProfileVersionDoc>;
  onReapplyClick: () => void;
};

export default function ProfileVersionRejected({ lastProfileVersionSnap, onReapplyClick }: Props) {
  const { t } = useTranslation('WelcomeScreen', { keyPrefix: 'ProfileVersionRejected' });

  const lastProfileVersionDoc = useMemo(
    () => lastProfileVersionSnap.data(),
    [lastProfileVersionSnap],
  );

  const insets = useInsets();

  const rejectionReason = useMemo(
    () => {
      switch (lastProfileVersionDoc.rejectionReason) {
        case ProfileVersionRejectionReason.INSTAGRAM_VERIFICATION: return t('rejectionReason.INSTAGRAM_VERIFICATION');
        case ProfileVersionRejectionReason.NO_BIKINI_PICTURES: return t('rejectionReason.NO_BIKINI_PICTURES');
        case ProfileVersionRejectionReason.PICTURES_CONTENT: return t('rejectionReason.PICTURES_CONTENT');
        case ProfileVersionRejectionReason.PICTURES_QUALITY: return t('rejectionReason.PICTURES_QUALITY');
        default: return t('rejectionReason.OTHER');
      }
    },
    [lastProfileVersionDoc.rejectionReason, t],
  );

  return (
    <VStack
      alignItems="stretch"
      gap={2}
      h="100%"
      pb={`max(${insets.bottom}, var(--chakra-space-2))`}
      pt={`max(${insets.top}, var(--chakra-space-2))`}
    >
      <Container>
        <HStack h={12} justifyContent="center">
          <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
        </HStack>
      </Container>

      <Container
        flex={1}
        minH={0}
        overflow="auto"
      >
        <VStack
          alignItems="stretch"
          gap={4}
          h="100%"
          justifyContent="center"
        >
          <Text fontSize="xl" fontWeight="bold" textAlign="center">
            {t('text1', { name: lastProfileVersionDoc.name })}
          </Text>

          <Text textAlign="center">
            {t('text2')}
          </Text>

          <Text fontSize="sm" textAlign="center">
            {rejectionReason}
          </Text>
        </VStack>
      </Container>

      <Container>
        <Button
          colorScheme="deepPurple"
          onClick={() => {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
            onReapplyClick();
          }}
          w="100%"
        >
          {t('reapplyButton.default')}
        </Button>
      </Container>
    </VStack>
  );
}
