import {
  AspectRatio,
  Box,
  Button,
  Container,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ProfileVersionDoc } from '@qupidu/hosting-common/src/common/collections/Profiles';
import { useInsets } from '@qupidu/hosting-common/src/components/InsetsProvider';
import LogoFull from '@qupidu/hosting-common/src/components/LogoFull';
import PictureImage from '@qupidu/hosting-common/src/components/PictureImage';
import useShowError from '@qupidu/hosting-common/src/hooks/useShowError';
import moment from 'moment';
import { Suspense, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type Props = {
  onComplete: (data: ProfileVersionDoc) => Promise<void>;
  profileVersionDoc: ProfileVersionDoc;
};

export function ConfirmationFormMain({
  onComplete,
  profileVersionDoc,
}: Props) {
  const { t } = useTranslation('WelcomeScreen', { keyPrefix: 'ProfileForm' });
  const { t: gt } = useTranslation('Gender');

  const insets = useInsets();

  const [sending, setSending] = useState(false);
  const showError = useShowError();
  const handleSubmitClick = useCallback(
    () => {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
      setSending(true);

      onComplete(profileVersionDoc)
        .finally(() => setSending(false))
        .catch(showError);
    },
    [profileVersionDoc, onComplete, showError],
  );

  return (
    <VStack
      alignItems="stretch"
      gap={2}
      h="100%"
      pb={`max(${insets.bottom}, var(--chakra-space-2))`}
      pt={`max(${insets.top}, var(--chakra-space-2))`}
    >
      <Container>
        <HStack h={12} justifyContent="center">
          <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
        </HStack>
      </Container>

      <Container
        flex={1}
        minH={0}
        overflow="auto"
      >
        <VStack
          alignItems="stretch"
          gap={4}
          h="100%"
        >
          {profileVersionDoc.pictureRefs?.length ? (
            <Grid
              autoRows="1fr"
              gap={2}
              templateColumns="repeat(3, 1fr)"
            >
              {profileVersionDoc.pictureRefs.map((pictureRef) => (
                <GridItem key={pictureRef.id}>
                  <AspectRatio ratio={9 / 16}>
                    <PictureImage
                      borderRadius="md"
                      cursor="pointer"
                      h="100%"
                      objectFit="cover"
                      pictureRef={pictureRef}
                    />
                  </AspectRatio>
                </GridItem>
              ))}

              {new Array(Math.ceil(
                profileVersionDoc.pictureRefs.length / 3,
              ) * 3 - profileVersionDoc.pictureRefs.length)
                .fill(null)
                .map((_, i) => (
                  <Box
                    _dark={{
                      backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    }}
                    _light={{
                      backgroundColor: 'rgba(0, 0, 0, 0.05)',
                    }}
                    borderRadius="md"
                  // eslint-disable-next-line react/no-array-index-key
                    key={i}
                  />
                ))}
            </Grid>
          ) : null}

          <Text fontSize="xl" fontWeight="bold" textAlign="center">
            {profileVersionDoc.name}
          </Text>

          <Text textAlign="center">
            {gt(profileVersionDoc.gender)}
            {', '}
            {Math.floor(
              moment.duration(
                moment().diff(moment(profileVersionDoc.birthDate).startOf('day')),
              ).asYears(),
            )}
          </Text>
        </VStack>
      </Container>

      <Container>
        <Button
          colorScheme="deepPurple"
          isLoading={sending}
          onClick={handleSubmitClick}
          w="100%"
        >
          {t('sendButton.default')}
        </Button>
      </Container>
    </VStack>
  );
}

export default function ConfirmationForm(props: Props) {
  return (
    <Suspense fallback={null}>
      <ConfirmationFormMain
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Suspense>
  );
}
