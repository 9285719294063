import {
  Button,
  Container,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ProfileVersionDoc } from '@qupidu/hosting-common/src/common/collections/Profiles';
import { useInsets } from '@qupidu/hosting-common/src/components/InsetsProvider';
import LogoFull from '@qupidu/hosting-common/src/components/LogoFull';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type Props = {
  lastProfileVersionSnap: QueryDocumentSnapshot<ProfileVersionDoc>;
  onContinueClick: () => void;
};

export default function ProfileVersionApproved({ lastProfileVersionSnap, onContinueClick }: Props) {
  const { t } = useTranslation('WelcomeScreen', { keyPrefix: 'ProfileVersionApproved' });

  const lastProfileVersionDoc = useMemo(
    () => lastProfileVersionSnap.data(),
    [lastProfileVersionSnap],
  );

  const insets = useInsets();

  return (
    <VStack
      alignItems="stretch"
      gap={2}
      h="100%"
      pb={`max(${insets.bottom}, var(--chakra-space-2))`}
      pt={`max(${insets.top}, var(--chakra-space-2))`}
    >
      <Container>
        <HStack h={12} justifyContent="center">
          <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
        </HStack>
      </Container>

      <Container
        flex={1}
        minH={0}
        overflow="auto"
      >
        <VStack
          alignItems="stretch"
          gap={4}
          h="100%"
          justifyContent="center"
        >
          <Text fontSize="xl" fontWeight="bold" textAlign="center">
            {t('text1', { name: lastProfileVersionDoc.name })}
          </Text>

          <Text textAlign="center">
            {t('text2')}
          </Text>

          <Text fontSize="sm" textAlign="center">
            {t('text3')}
          </Text>
        </VStack>
      </Container>

      <Container>
        <Button
          colorScheme="deepPurple"
          onClick={() => {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
            onContinueClick();
          }}
          w="100%"
        >
          {t('continueButton.default')}
        </Button>
      </Container>
    </VStack>
  );
}
