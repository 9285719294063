import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';

import type Preferences from '../Preferences';

import { useFirestore } from '../../components/FirestoreProvider';
import AppLanguage from '../AppLanguage';
import { type CityDoc } from './Cities';
import { type PictureDoc } from './Picture';
import { type ProfileDoc, type ProfileField } from './Profiles';

export enum ArrangementStatus {
  DRAFTED = 'DRAFTED',
  PAID = 'PAID',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export interface ArrangementDoc {
  _v: 1;
  cityRef: DocumentReference<CityDoc>;
  createdAt: Timestamp;
  description: string;
  femaleParticipantPlaces: number;
  femaleProfilePreferences?: Preferences;
  femaleProfileRequiredFields?: ProfileField[];
  maleParticipantPlaces: number;
  maleProfilePreferences?: Preferences;
  maleProfileRequiredFields?: ProfileField[];
  name: string;
  organizerRef: DocumentReference<ProfileDoc>;
  pictureRefs: DocumentReference<PictureDoc>[];
  publishedAt?: Timestamp;
  status: ArrangementStatus;
  translations?: Partial<Record<AppLanguage, Partial<Pick<ArrangementDoc, 'description' | 'name'>>>>;
  unpublishedAt?: Timestamp;
  updatedAt: Timestamp;
}

export const isArrangementDoc = (
  data?: DocumentData,
): data is ArrangementDoc => true;

export const isArrangementRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<ArrangementDoc> => ref.parent.id === 'arrangements';

function isArrangementSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<ArrangementDoc>;
function isArrangementSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<ArrangementDoc>;
function isArrangementSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<ArrangementDoc> | QueryDocumentSnapshot<ArrangementDoc> {
  return isArrangementRef(snap.ref);
}

export { isArrangementSnap };

export const getArrangementsCollectionRef = (firestore: Firestore) => collection(firestore, 'arrangements') as CollectionReference<ArrangementDoc>;

export const useArrangementsCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => getArrangementsCollectionRef(firestore), [firestore]);
};
