import {
  AspectRatio,
  Box,
  Center,
  Container,
  Grid,
  Text,
} from '@chakra-ui/react';
import {
  ArrangementStatus,
  useArrangementsCollectionRef,
} from '@qupidu/hosting-common/src/common/collections/Arrangements';
import Catch from '@qupidu/hosting-common/src/components/Catch';
import useQuerySnapshot from '@qupidu/hosting-common/src/hooks/useQuerySnapshot';
import {
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Arrangement, { ArrangementLoading } from './Arrangement';

export function ArrangementGridLoading() {
  return (
    <Grid gap={2} gridAutoRows="1fr" templateColumns="repeat(2, 1fr)">
      <AspectRatio ratio={9 / 16}>
        <ArrangementLoading />
      </AspectRatio>
      <AspectRatio ratio={9 / 16}>
        <ArrangementLoading />
      </AspectRatio>
      <AspectRatio ratio={9 / 16}>
        <ArrangementLoading />
      </AspectRatio>
      <AspectRatio ratio={9 / 16}>
        <ArrangementLoading />
      </AspectRatio>
    </Grid>
  );
}

export function ArrangementGridMain() {
  const { t } = useTranslation('ArrangementsScreen', { keyPrefix: 'ArrangementList' });

  const arrangementsCollectionRef = useArrangementsCollectionRef();
  const { snap: arrangementsSnap } = useQuerySnapshot(
    query(
      arrangementsCollectionRef,
      where('status', '==', ArrangementStatus.PUBLISHED),
      orderBy('createdAt', 'asc'),
      limit(100),
    ),
  );

  if (!arrangementsSnap) {
    return (
      <ArrangementGridLoading />
    );
  }

  if (arrangementsSnap.docs.length) {
    return (
      <Grid autoRows="1fr" gap={2} templateColumns="repeat(2, 1fr)">
        {arrangementsSnap.docs.map((arrangementSnap) => (
          <AspectRatio
            key={arrangementSnap.id}
            ratio={9 / 16}
          >
            <Arrangement
              arrangementSnap={arrangementSnap}
              scrollSnapAlign="center"
            />
          </AspectRatio>
        ))}

        {new Array(Math.ceil(arrangementsSnap.docs.length / 2) * 2 - arrangementsSnap.docs.length)
          .fill(null)
          .map((_, i) => (
            <Box
              _dark={{
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
              }}
              _light={{
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
              }}
              borderRadius="md"
              // eslint-disable-next-line react/no-array-index-key
              key={i}
            />
          ))}
      </Grid>
    );
  }

  return (
    <Container height="100%" maxW="lg">
      <Center height="100%">
        <Text textAlign="center">
          {t('emptyList.body')}
        </Text>
      </Center>
    </Container>
  );
}

export default function ArrangementGrid() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<ArrangementGridLoading />}>
        <ArrangementGridMain />
      </Suspense>
    </Catch>
  );
}
