import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';

import type Preferences from '../Preferences';

import { useFirestore } from '../../components/FirestoreProvider';
import AppLanguage from '../AppLanguage';
import { type PictureDoc } from './Picture';
import { type ProfileDoc, type ProfileField } from './Profiles';

export enum JobStatus {
  DRAFTED = 'DRAFTED',
  PAID = 'PAID',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export interface JobDoc {
  _v: 1;
  createdAt: Timestamp;
  description: string;
  femaleParticipantPlaces: number;
  femaleProfilePreferences?: Preferences;
  femaleProfileRequiredFields?: ProfileField[];
  maleParticipantPlaces: number;
  maleProfilePreferences?: Preferences;
  maleProfileRequiredFields?: ProfileField[];
  name: string;
  organizerRef: DocumentReference<ProfileDoc>;
  pictureRefs: DocumentReference<PictureDoc>[];
  publishedAt?: Timestamp;
  status: JobStatus;
  translations?: Partial<Record<AppLanguage, Partial<Pick<JobDoc, 'description' | 'name'>>>>;
  unpublishedAt?: Timestamp;
  updatedAt: Timestamp;
}

export const isJobDoc = (
  doc?: DocumentData,
): doc is JobDoc => true;

export const isJobRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<JobDoc> => ref.parent.id === 'jobs';

function isJobSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<JobDoc>;
function isJobSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<JobDoc>;
function isJobSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<JobDoc> | QueryDocumentSnapshot<JobDoc> {
  return isJobRef(snap.ref);
}

export { isJobSnap };

export const getJobsCollectionRef = (firestore: Firestore): CollectionReference<JobDoc> => collection(firestore, 'jobs') as CollectionReference<JobDoc>;

export const useJobsCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => getJobsCollectionRef(firestore), [firestore]);
};
