import {
  Button,
  Container,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { ProfileVersionDoc } from '@qupidu/hosting-common/src/common/collections/Profiles';
import RelationshipStyle from '@qupidu/hosting-common/src/common/RelationshipStyle';
import { useInsets } from '@qupidu/hosting-common/src/components/InsetsProvider';
import LogoFull from '@qupidu/hosting-common/src/components/LogoFull';
import RelationshipStylesFormControl from '@qupidu/hosting-common/src/components/RelationshipStylesFormControl';
import { Formik } from 'formik';
import { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export type Props = {
  data: Pick<ProfileVersionDoc, 'relationshipStyles'> | undefined;
  onComplete: (data: Required<Pick<ProfileVersionDoc, 'relationshipStyles'>>) => void;
};

export function RelationshipStylesFormMain({
  data, onComplete,
}: Props) {
  const { t } = useTranslation('WelcomeScreen', { keyPrefix: 'ProfileForm' });

  const schema = useMemo(
    () => yup.object().shape({
      relationshipStyles: yup
        .array()
        .label(t('relationshipStyles.label'))
        .compact()
        .min(1)
        .required()
        .of(
          yup
            .string()
            .label(t('relationshipStyles.item.label'))
            .oneOf(Object.values(RelationshipStyle))
            .required(),
        ),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      relationshipStyles: data?.relationshipStyles ?? [],
    }),
    [data],
  );

  const insets = useInsets();

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onComplete}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        isValidating,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          gap={2}
          h="100%"
          minH={0}
          noValidate
          onSubmit={(e) => {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
          pb={`max(${insets.bottom}, var(--chakra-space-2))`}
          pt={`max(${insets.top}, var(--chakra-space-2))`}
        >
          <Container>
            <HStack h={12} justifyContent="center">
              <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
            </HStack>
          </Container>

          <Container
            flex={1}
            minH={0}
            overflow="auto"
          >
            <VStack alignItems="stretch" flex={1} gap={8} overflow="auto">
              <RelationshipStylesFormControl
                helperText={t('relationshipStyles.helperText')}
                label={t('relationshipStyles.label')}
                name="relationshipStyles"
              />
            </VStack>
          </Container>

          <Container>
            <Button
              colorScheme={isValid ? undefined : 'red'}
              isLoading={isValidating || isSubmitting}
              type="submit"
              w="100%"
            >
              {isValid ? t('nextButton.default') : t('nextButton.invalid')}
            </Button>
          </Container>
        </VStack>
      )}
    </Formik>
  );
}

export default function RelationshipStylesForm(props: Props) {
  return (
    <Suspense fallback={null}>
      <RelationshipStylesFormMain
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Suspense>
  );
}
