import {
  Button,
  Container,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { useCitiesCollectionRef } from '@qupidu/hosting-common/src/common/collections/Cities';
import { usePicturesCollectionRef } from '@qupidu/hosting-common/src/common/collections/Picture';
import {
  useVenturesCollectionRef,
  VentureDoc,
  VentureLabel,
  VentureStatus,
} from '@qupidu/hosting-common/src/common/collections/Ventures';
import ensureWriteAccess from '@qupidu/hosting-common/src/common/ensureWriteAccess';
import CheckboxFormControl from '@qupidu/hosting-common/src/components/CheckboxFormControl';
import CityFormControl from '@qupidu/hosting-common/src/components/CityFormControl';
import { useInsets } from '@qupidu/hosting-common/src/components/InsetsProvider';
import InstagramFormControl from '@qupidu/hosting-common/src/components/InstagramFormControl';
import LogoFull from '@qupidu/hosting-common/src/components/LogoFull';
import NumberFormControl from '@qupidu/hosting-common/src/components/NumberFormControl';
import PicturesFormControl from '@qupidu/hosting-common/src/components/PicturesFormControl';
import {
  useMyProfileRef,
} from '@qupidu/hosting-common/src/components/refProviders/MyProfileRefProvider';
import TextareaFormControl from '@qupidu/hosting-common/src/components/TextareaFormControl';
import TextFormControl from '@qupidu/hosting-common/src/components/TextFormControl';
import useTelegramGetVentureInvoiceUrl from '@qupidu/hosting-common/src/functions/useTelegramGetVentureInvoiceUrl';
import {
  doc,
  DocumentReference,
  getDoc,
  setDoc,
  Timestamp,
} from 'firebase/firestore';
import { Formik } from 'formik';
import { compact } from 'lodash';
import moment from 'moment-timezone';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export type Props = {
  onComplete: (ventureRef: DocumentReference<VentureDoc>) => void;
};

export default function VentureForm({ onComplete }: Props) {
  const { t } = useTranslation('VentureCreateScreen', { keyPrefix: 'VentureForm' });

  const schema = useMemo(
    () => yup.object().shape({
      cityId: yup
        .string()
        .label(t('cityId.label'))
        .required(),
      description: yup
        .string()
        .label(t('description.label'))
        .required(),
      endsAt: yup
        .string()
        .label(t('endsAt.label'))
        .required(),
      femaleParticipantPlaces: yup
        .number()
        .min(0)
        .max(1000)
        .label(t('femaleParticipantPlaces.label'))
        .required(),
      instagramTag: yup
        .string()
        .matches(/^[a-zA-Z0-9._]+$/, t('instagramTag.matchesError'))
        .label(t('instagramTag.label')),
      labels: yup
        .array()
        .label(t('labels.label'))
        .min(1)
        .max(20)
        .of(
          yup
            .string()
            .label(t('labels.item.label'))
            .oneOf(Object.values(VentureLabel))
            .required(),
        )
        .required(),
      maleParticipantPlaces: yup
        .number()
        .min(0)
        .max(1000)
        .label(t('maleParticipantPlaces.label'))
        .required(),
      name: yup
        .string()
        .label(t('name.label'))
        .required(),
      pictureIds: yup
        .array()
        .label(t('pictureIds.label'))
        .compact()
        .min(3)
        .max(9)
        .of(
          yup
            .string()
            .label(t('pictureIds.item.label'))
            .required(),
        )
        .required(),
      startsAt: yup
        .string()
        .label(t('startsAt.label'))
        .required(),
    }),
    [t],
  );

  const myProfileRef = useMyProfileRef();

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      cityId: 'WAW',
      description: '',
      endsAt: moment
        .utc()
        .add(1, 'month')
        .add(1, 'week')
        .set({
          hour: 18,
          millisecond: 0,
          minute: 0,
          second: 0,
        })
        .format('YYYY-MM-DDTHH:mm:ss'),
      femaleParticipantPlaces: 1,
      instagramTag: '',
      labels: [],
      maleParticipantPlaces: 0,
      name: '',
      pictureIds: [],
      startsAt: moment
        .utc()
        .add(1, 'month')
        .set({
          hour: 10,
          millisecond: 0,
          minute: 0,
          second: 0,
        })
        .format('YYYY-MM-DDTHH:mm:ss'),
      translations: {
        ru: {
          description: '',
          name: '',
        },
        uk: {
          description: '',
          name: '',
        },
      },
    }),
    [],
  );

  const venturesCollectionRef = useVenturesCollectionRef();
  const citiesCollectionRef = useCitiesCollectionRef();
  const picturesCollectionRef = usePicturesCollectionRef();

  const insets = useInsets();

  const telegramGetVentureInvoiceUrl = useTelegramGetVentureInvoiceUrl();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      await ensureWriteAccess();

      const ventureRef = doc(venturesCollectionRef);
      const cityRef = doc(citiesCollectionRef, values.cityId);
      const citySnap = await getDoc(cityRef);
      const cityDoc = citySnap.data();

      const endsAt = Timestamp.fromDate(
        cityDoc?.timezone
          ? moment.tz(values.endsAt, cityDoc?.timezone).toDate()
          : moment(values.endsAt).toDate(),
      );

      const startsAt = Timestamp.fromDate(
        cityDoc?.timezone
          ? moment.tz(values.startsAt, cityDoc?.timezone).toDate()
          : moment(values.startsAt).toDate(),
      );

      await setDoc(
        ventureRef,
        {
          _v: 1,
          cityRef,
          createdAt: Timestamp.now(),
          description: values.description,
          endsAt,
          femaleParticipantPlaces: values.femaleParticipantPlaces,
          instagramTag: values.instagramTag,
          labels: values.labels,
          maleParticipantPlaces: values.maleParticipantPlaces,
          name: values.name,
          organizerRef: myProfileRef,
          pictureRefs: compact(values.pictureIds).map((id) => doc(picturesCollectionRef, id)),
          startsAt,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          status: VentureStatus.DRAFTED,
          updatedAt: Timestamp.now(),
        },
      );

      const { data: { url } } = await telegramGetVentureInvoiceUrl({ ventureId: ventureRef.id });

      await new Promise<'cancelled' | 'failed' | 'paid' | 'pending'>(
        (res) => { window.Telegram.WebApp.openInvoice(url, res); },
      );

      onComplete(ventureRef);
    },
    [
      citiesCollectionRef,
      myProfileRef,
      onComplete,
      picturesCollectionRef,
      telegramGetVentureInvoiceUrl,
      venturesCollectionRef,
    ],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        isValidating,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          gap={2}
          h="100%"
          minH={0}
          noValidate
          onSubmit={(e) => {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
          pt={`max(${insets.top}, var(--chakra-space-2))`}
        >
          <Container>
            <HStack h={12} justifyContent="center">
              <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
            </HStack>
          </Container>

          <Container
            flex={1}
            minH={0}
            overflowY="auto"
          >
            <VStack alignItems="stretch" gap={4}>
              <PicturesFormControl
                label={t('pictureIds.label')}
                name="pictureIds"
              />

              <TextFormControl
                label={t('name.label')}
                name="name"
              />

              <TextareaFormControl
                label={t('description.label')}
                name="description"
                placeholder={t('description.placeholder')}
                rows={10}
              />

              <InstagramFormControl
                label={t('instagramTag.label')}
                name="instagramTag"
                type="text"
              />

              <CityFormControl
                aroundLatLngViaIP
                label={t('cityId.label')}
                name="cityId"
                placeholder={t('cityId.placeholder')}
              />

              <CheckboxFormControl
                label={t('labels.label')}
                name="labels"
                options={{
                  /* eslint-disable perfectionist/sort-objects */
                  [VentureLabel.DINNER]: t(`labels.option.${VentureLabel.DINNER}`),
                  [VentureLabel.NIGHTLIFE]: t(`labels.option.${VentureLabel.NIGHTLIFE}`),
                  /* eslint-enable perfectionist/sort-objects */
                }}
              />

              <TextFormControl
                label={t('startsAt.label')}
                name="startsAt"
                type="datetime-local"
              />

              <TextFormControl
                label={t('endsAt.label')}
                name="endsAt"
                type="datetime-local"
              />

              <NumberFormControl
                label={t('femaleParticipantPlaces.label')}
                max={10}
                min={0}
                name="femaleParticipantPlaces"
                step={1}
              />

              <NumberFormControl
                label={t('maleParticipantPlaces.label')}
                max={10}
                min={0}
                name="maleParticipantPlaces"
                step={1}
              />
            </VStack>
          </Container>

          <Container>
            <Button
              colorScheme={isValid ? 'indigo' : 'red'}
              isLoading={isValidating || isSubmitting}
              loadingText={t('createButton.loading')}
              type="submit"
              w="100%"
            >
              {t('createButton.default')}
            </Button>
          </Container>
        </VStack>
      )}
    </Formik>
  );
}
