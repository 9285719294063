import {
  Button,
  ButtonGroup,
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  ProfileIndexRecord,
  ProfileStatus,
} from '@qupidu/hosting-common/src/common/collections/Profiles';
import CupSize from '@qupidu/hosting-common/src/common/CupSize';
import ensureWriteAccess from '@qupidu/hosting-common/src/common/ensureWriteAccess';
import Goal from '@qupidu/hosting-common/src/common/Goal';
import PantiesSize from '@qupidu/hosting-common/src/common/PantiesSize';
import RelationshipStyle from '@qupidu/hosting-common/src/common/RelationshipStyle';
import Sexuality from '@qupidu/hosting-common/src/common/Sexuality';
import {
  useAlgoliaSearchClient,
} from '@qupidu/hosting-common/src/components/AlgoliaSearchClientProvider';
import Catch from '@qupidu/hosting-common/src/components/Catch';
import { useInsets } from '@qupidu/hosting-common/src/components/InsetsProvider';
import {
  useMyProfileRef,
} from '@qupidu/hosting-common/src/components/refProviders/MyProfileRefProvider';
import useDocumentSnapshot from '@qupidu/hosting-common/src/hooks/useDocumentSnapshot';
import useJoyrideLocale from '@qupidu/hosting-common/src/hooks/useJoyrideLocale';
import useJoyrideStyles from '@qupidu/hosting-common/src/hooks/useJoyrideStyles';
import useShowError from '@qupidu/hosting-common/src/hooks/useShowError';
import { setDoc } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import {
  ChangeEvent,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuBadgeCheck, LuSearch, LuX } from 'react-icons/lu';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import { useNavigate } from 'react-router-dom';

import ErrorFallbackScreen from '../ErrorFallbackScreen';
import ProfileGrid, { ProfileGridLoading } from './ProfileGrid';

export function ProfilesScreenMain() {
  const { i18n, t } = useTranslation('ProfilesScreen');
  const { t: rst } = useTranslation('RelationshipStyle');
  const { t: st } = useTranslation('Sexuality');
  const { t: gt } = useTranslation('Goal');

  const insets = useInsets();

  const myProfileRef = useMyProfileRef();

  const { snap: myProfileSnap } = useDocumentSnapshot(myProfileRef);

  const showError = useShowError();
  const navigate = useNavigate();
  const [isUnpublishLoading, setUnpublishLoading] = useState<boolean>(false);
  const [isPublishLoading, setPublishLoading] = useState<boolean>(false);
  const onPublishClick = useCallback(
    () => {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');

      setPublishLoading(true);

      mixpanel.track('Publish Profile Clicked');

      (async () => {
        try {
          await ensureWriteAccess();
        } catch (err) {
          /* do nothing */
        }

        if (!myProfileSnap?.exists()) {
          navigate('/welcome');
          return;
        }

        const myProfileDoc = myProfileSnap.data();

        if (!myProfileDoc.isVerified) {
          const calendlyUrl = new URL(`https://calendly.com/qupidu/premium-user-verification-${i18n.language}`);
          calendlyUrl.searchParams.set('a1', myProfileSnap.id);
          window.Telegram.WebApp.openLink(calendlyUrl.toString());
          return;
        }

        await setDoc(myProfileSnap.ref, { status: ProfileStatus.PUBLISHED }, { merge: true });
      })()
        .finally(() => setPublishLoading(false))
        .catch(showError);
    },
    [
      i18n.language,
      myProfileSnap,
      navigate,
      showError,
    ],
  );

  const onUnpublishClick = useCallback(
    () => {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');

      setUnpublishLoading(true);

      mixpanel.track('Unpublish Profile Clicked');

      (async () => {
        try {
          await ensureWriteAccess();
        } catch (err) {
          /* do nothing */
        }

        if (!myProfileSnap?.exists()) {
          navigate('/welcome');
          return;
        }

        await setDoc(myProfileSnap.ref, { status: ProfileStatus.UNPUBLISHED }, { merge: true });
      })()
        .finally(() => setPublishLoading(false))
        .catch(showError);
    },
    [myProfileSnap, navigate, showError],
  );

  const locale = useJoyrideLocale();

  const styles = useJoyrideStyles();

  const steps = useMemo<Step[]>(() => ([
    {
      content: t('tour.welcome.content'),
      placement: 'center',
      target: 'body',
      title: t('tour.welcome.title'),
    },
    {
      content: t('tour.profilesSponsorButton.content'),
      target: '.profilesSponsorButton',
    },
    {
      content: t('tour.profileItem.content'),
      target: '.profileItem',
    },
  ]), [t]);

  const tourRun = useMemo(() => window.localStorage.getItem('joyride/ProfilesScreenComplete') !== 'TRUE', []);

  const handleTourChange = useCallback(
    ({ type }: CallBackProps) => {
      if (type === 'tour:end') {
        window.localStorage.setItem('joyride/ProfilesScreenComplete', 'TRUE');
      }
    },
    [],
  );

  const [search, setSearch] = useState<string>(() => {
    try {
      const v = localStorage.getItem('ProfilesScreen::search');
      if (typeof v === 'string') {
        return v;
      }
      return '';
    } catch (err) {
      return '';
    }
  });

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
      try {
        if (e.target.value === '') {
          localStorage.removeItem('ProfilesScreen::search');
        } else {
          localStorage.setItem('ProfilesScreen::search', e.target.value);
        }
      } catch (err) {
        /* do nothing */
      }
    },
    [],
  );

  const [searchDebounced, setSearchDebounced] = useState<string>(search);

  useEffect(
    () => {
      const timeout = setTimeout(
        () => setSearchDebounced(search),
        500,
      );

      return () => clearTimeout(timeout);
    },
    [search],
  );

  const [isVerified, setVerified] = useState<boolean>(() => {
    try {
      const v = localStorage.getItem('ProfilesScreen::isVerified');
      if (v === 'false') {
        return false;
      }
      return true;
    } catch (err) {
      return true;
    }
  });

  const handleVerifiedChange = useCallback(
    (v: boolean) => {
      setVerified(v);
      try {
        if (v !== false) {
          localStorage.removeItem('ProfilesScreen::isVerified');
        } else {
          localStorage.setItem('ProfilesScreen::isVerified', 'false');
        }
      } catch (err) {
        /* do nothing */
      }
    },
    [],
  );

  const [ageGroup, setAgeGroup] = useState<number | undefined>(() => {
    try {
      const v = localStorage.getItem('ProfilesScreen::ageGroup');
      if (v) {
        return parseInt(v, 10);
      }
      return undefined;
    } catch (err) {
      return undefined;
    }
  });

  const handleAgeGroupChange = useCallback(
    (v: number | undefined) => {
      setAgeGroup(v);
      try {
        if (v === undefined) {
          localStorage.removeItem('ProfilesScreen::ageGroup');
        } else {
          localStorage.setItem('ProfilesScreen::ageGroup', v.toString());
        }
      } catch (err) {
        /* do nothing */
      }
    },
    [],
  );

  const [cupSizeGroup, setCupSizeGroup] = useState<number | undefined>(() => {
    try {
      const v = localStorage.getItem('ProfilesScreen::cupSizeGroup');
      if (v) {
        return parseInt(v, 10);
      }
      return undefined;
    } catch (err) {
      return undefined;
    }
  });

  const handleCupSizeGroupChange = useCallback(
    (v: number | undefined) => {
      setCupSizeGroup(v);
      try {
        if (v === undefined) {
          localStorage.removeItem('ProfilesScreen::cupSizeGroup');
        } else {
          localStorage.setItem('ProfilesScreen::cupSizeGroup', v.toString());
        }
      } catch (err) {
        /* do nothing */
      }
    },
    [],
  );

  const [pantiesSizeGroup, setPantiesSizeGroup] = useState<number | undefined>(() => {
    try {
      const v = localStorage.getItem('ProfilesScreen::pantiesSizeGroup');
      if (v) {
        return parseInt(v, 10);
      }
      return undefined;
    } catch (err) {
      return undefined;
    }
  });

  const handlePantiesSizeGroupChange = useCallback(
    (v: number | undefined) => {
      setPantiesSizeGroup(v);
      try {
        if (v === undefined) {
          localStorage.removeItem('ProfilesScreen::pantiesSizeGroup');
        } else {
          localStorage.setItem('ProfilesScreen::pantiesSizeGroup', v.toString());
        }
      } catch (err) {
        /* do nothing */
      }
    },
    [],
  );

  const [heightGroup, setHeightGroup] = useState<number | undefined>(() => {
    try {
      const v = localStorage.getItem('ProfilesScreen::heightGroup');
      if (v) {
        return parseInt(v, 10);
      }
      return undefined;
    } catch (err) {
      return undefined;
    }
  });

  const handleHeightGroupChange = useCallback(
    (v: number | undefined) => {
      setHeightGroup(v);
      try {
        if (v === undefined) {
          localStorage.removeItem('ProfilesScreen::heightGroup');
        } else {
          localStorage.setItem('ProfilesScreen::heightGroup', v.toString());
        }
      } catch (err) {
        /* do nothing */
      }
    },
    [],
  );

  const [bodyCountGroup, setBodyCountGroup] = useState<number | undefined>(() => {
    try {
      const v = localStorage.getItem('ProfilesScreen::bodyCountGroup');
      if (v) {
        return parseInt(v, 10);
      }
      return undefined;
    } catch (err) {
      return undefined;
    }
  });

  const handleBodyCountGroupChange = useCallback(
    (v: number | undefined) => {
      setBodyCountGroup(v);
      try {
        if (v === undefined) {
          localStorage.removeItem('ProfilesScreen::bodyCountGroup');
        } else {
          localStorage.setItem('ProfilesScreen::bodyCountGroup', v.toString());
        }
      } catch (err) {
        /* do nothing */
      }
    },
    [],
  );

  const [sexuality, setSexuality] = useState<Sexuality | undefined>(() => {
    try {
      return (localStorage.getItem('ProfilesScreen::sexuality') as Sexuality) ?? undefined;
    } catch (err) {
      return undefined;
    }
  });

  const handleSexualityChange = useCallback(
    (v: Sexuality | undefined) => {
      setSexuality(v);
      try {
        if (v === undefined) {
          localStorage.removeItem('ProfilesScreen::sexuality');
        } else {
          localStorage.setItem('ProfilesScreen::sexuality', v.toString());
        }
      } catch (err) {
        /* do nothing */
      }
    },
    [],
  );

  const [relationshipStyle, setRelationshipStyle] = useState<RelationshipStyle | undefined>(() => {
    try {
      return (localStorage.getItem('ProfilesScreen::relationshipStyle') as RelationshipStyle) ?? undefined;
    } catch (err) {
      return undefined;
    }
  });

  const handleRelationshipStyleChange = useCallback(
    (v: RelationshipStyle | undefined) => {
      setRelationshipStyle(v);
      try {
        if (v === undefined) {
          localStorage.removeItem('ProfilesScreen::relationshipStyle');
        } else {
          localStorage.setItem('ProfilesScreen::relationshipStyle', v.toString());
        }
      } catch (err) {
        /* do nothing */
      }
    },
    [],
  );

  const [goal, setGoal] = useState<Goal | undefined>(() => {
    try {
      return (localStorage.getItem('ProfilesScreen::goal') as Goal) ?? undefined;
    } catch (err) {
      return undefined;
    }
  });

  const handleGoalChange = useCallback(
    (v: Goal | undefined) => {
      setGoal(v);
      try {
        if (v === undefined) {
          localStorage.removeItem('ProfilesScreen::goal');
        } else {
          localStorage.setItem('ProfilesScreen::goal', v.toString());
        }
      } catch (err) {
        /* do nothing */
      }
    },
    [],
  );

  const client = useAlgoliaSearchClient();
  const profilesIndex = useMemo(() => client.initIndex('profiles'), [client]);
  const [profilesResult, setProfilesResult] = useState<ProfileIndexRecord[]>();
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(
    () => {
      const facetFilters: string[] = [];
      const numericFilters: string[] = [];

      switch (ageGroup) {
        case 0: {
          numericFilters.push('age < 20');
          break;
        }
        case 1: {
          numericFilters.push('age:20 TO 24');
          break;
        }
        case 2: {
          numericFilters.push('age:25 TO 29');
          break;
        }
        case 3: {
          numericFilters.push('age >= 30');
          break;
        }
        default: {
          break;
        }
      }

      switch (cupSizeGroup) {
        case 0: {
          numericFilters.push(`cupSize < ${CupSize.C}`);
          break;
        }
        case 1: {
          numericFilters.push(`cupSize = ${CupSize.C}`);
          break;
        }
        case 2: {
          numericFilters.push(`cupSize > ${CupSize.C}`);
          break;
        }
        default: {
          break;
        }
      }

      switch (heightGroup) {
        case 0: {
          numericFilters.push('height < 165');
          break;
        }
        case 1: {
          numericFilters.push('height: 165 TO 175');
          break;
        }
        case 2: {
          numericFilters.push('height > 175');
          break;
        }
        default: {
          break;
        }
      }

      switch (pantiesSizeGroup) {
        case 0: {
          numericFilters.push(`pantiesSize < ${PantiesSize.M}`);
          break;
        }
        case 1: {
          numericFilters.push(`pantiesSize = ${PantiesSize.M}`);
          break;
        }
        case 2: {
          numericFilters.push(`pantiesSize > ${PantiesSize.M}`);
          break;
        }
        default: {
          break;
        }
      }

      switch (bodyCountGroup) {
        case 0: {
          numericFilters.push('bodyCount = 0');
          break;
        }
        case 1: {
          numericFilters.push('bodyCount: 1 TO 9');
          break;
        }
        case 2: {
          numericFilters.push('bodyCount > 9');
          break;
        }
        default: {
          break;
        }
      }

      if (sexuality) {
        facetFilters.push(`sexuality: ${sexuality}`);
      }

      if (relationshipStyle) {
        facetFilters.push(`relationshipStyles: ${relationshipStyle}`);
      }

      if (goal) {
        facetFilters.push(`goals: ${goal}`);
      }

      if (isVerified) {
        facetFilters.push('isVerified: true');
      }

      profilesIndex
        .search<ProfileIndexRecord>(
        searchDebounced,
        {
          aroundLatLngViaIP: true,
          facetFilters,
          hitsPerPage: 1000,
          numericFilters,
        },
      )
        .then(
          ({ hits, nbHits }) => {
            setProfilesResult(hits);
            setTotalCount(nbHits);
          },
        )
        .catch(showError);
    },
    [
      ageGroup,
      bodyCountGroup,
      cupSizeGroup,
      goal,
      heightGroup,
      isVerified,
      pantiesSizeGroup,
      profilesIndex,
      relationshipStyle,
      searchDebounced,
      sexuality,
      showError,
    ],
  );

  return (
    <VStack alignItems="stretch" className="profilesScreen" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack h={12} justifyContent="center">
          <Heading fontSize="2xl">
            {t('title')}
          </Heading>
        </HStack>
      </Container>

      <Joyride
        callback={handleTourChange}
        continuous
        hideCloseButton
        locale={locale}
        run={tourRun}
        showSkipButton
        steps={steps}
        styles={styles}
      />

      <Container
        maxW="lg"
        overflow="auto"
        pb={2}
      >
        <HStack gap={2}>
          <Button
            flexShrink={0}
            isActive={isVerified}
            leftIcon={<Icon as={LuBadgeCheck} />}
            onClick={() => handleVerifiedChange(!isVerified)}
            size="sm"
            variant="outline"
          >
            Verified
          </Button>

          <InputGroup
            size="sm"
          >
            <InputLeftElement pointerEvents="none">
              <Icon as={LuSearch} />
            </InputLeftElement>

            <Input
              borderRadius="md"
              flexShrink={0}
              onChange={handleSearchChange}
              placeholder="Jane 20 Warsaw"
              value={search}
              w="200px"
            />
          </InputGroup>

          <ButtonGroup
            flexShrink={0}
            isAttached
            size="sm"
            variant="outline"
          >
            <IconButton
              aria-label=""
              icon={<Icon as={LuX} />}
              isActive={ageGroup === undefined}
              onClick={() => handleAgeGroupChange(undefined)}
            />
            <Button isActive={ageGroup === 0} onClick={() => handleAgeGroupChange(0)}>
              18-19
            </Button>
            <Button isActive={ageGroup === 1} onClick={() => handleAgeGroupChange(1)}>
              20-24
            </Button>
            <Button isActive={ageGroup === 2} onClick={() => handleAgeGroupChange(2)}>
              25-29
            </Button>
            <Button isActive={ageGroup === 3} onClick={() => handleAgeGroupChange(3)}>
              30+
            </Button>
          </ButtonGroup>

          <ButtonGroup
            flexShrink={0}
            isAttached
            size="sm"
            variant="outline"
          >
            <IconButton
              aria-label=""
              icon={<Icon as={LuX} />}
              isActive={cupSizeGroup === undefined}
              onClick={() => handleCupSizeGroupChange(undefined)}
            />
            <Button
              isActive={cupSizeGroup === 0}
              onClick={() => handleCupSizeGroupChange(0)}
            >
              AA-B
            </Button>
            <Button
              isActive={cupSizeGroup === 1}
              onClick={() => handleCupSizeGroupChange(1)}
            >
              C
            </Button>
            <Button
              isActive={cupSizeGroup === 2}
              onClick={() => handleCupSizeGroupChange(2)}
            >
              D+
            </Button>
          </ButtonGroup>

          <ButtonGroup
            flexShrink={0}
            isAttached
            size="sm"
            variant="outline"
          >
            <IconButton
              aria-label=""
              icon={<Icon as={LuX} />}
              isActive={pantiesSizeGroup === undefined}
              onClick={() => handlePantiesSizeGroupChange(undefined)}
            />
            <Button
              isActive={pantiesSizeGroup === 0}
              onClick={() => handlePantiesSizeGroupChange(0)}
            >
              XXS-S
            </Button>
            <Button
              isActive={pantiesSizeGroup === 1}
              onClick={() => handlePantiesSizeGroupChange(1)}
            >
              M
            </Button>
            <Button
              isActive={pantiesSizeGroup === 2}
              onClick={() => handlePantiesSizeGroupChange(2)}
            >
              L-XXL
            </Button>
          </ButtonGroup>

          <ButtonGroup
            flexShrink={0}
            isAttached
            size="sm"
            variant="outline"
          >
            <IconButton
              aria-label=""
              icon={<Icon as={LuX} />}
              isActive={heightGroup === undefined}
              onClick={() => handleHeightGroupChange(undefined)}
            />
            <Button
              isActive={heightGroup === 0}
              onClick={() => handleHeightGroupChange(0)}
            >
              ..164
            </Button>
            <Button
              isActive={heightGroup === 1}
              onClick={() => handleHeightGroupChange(1)}
            >
              165..175
            </Button>
            <Button
              isActive={heightGroup === 2}
              onClick={() => handleHeightGroupChange(2)}
            >
              176..
            </Button>
          </ButtonGroup>

          <ButtonGroup
            flexShrink={0}
            isAttached
            size="sm"
            variant="outline"
          >
            <IconButton
              aria-label=""
              icon={<Icon as={LuX} />}
              isActive={bodyCountGroup === undefined}
              onClick={() => handleBodyCountGroupChange(undefined)}
            />
            <Button
              isActive={bodyCountGroup === 0}
              onClick={() => handleBodyCountGroupChange(0)}
            >
              Virgin
            </Button>
            <Button
              isActive={bodyCountGroup === 1}
              onClick={() => handleBodyCountGroupChange(1)}
            >
              1..9
            </Button>
            <Button
              isActive={bodyCountGroup === 2}
              onClick={() => handleBodyCountGroupChange(2)}
            >
              10+
            </Button>
          </ButtonGroup>

          <ButtonGroup
            flexShrink={0}
            isAttached
            size="sm"
            variant="outline"
          >
            <IconButton
              aria-label=""
              icon={<Icon as={LuX} />}
              isActive={sexuality === undefined}
              onClick={() => handleSexualityChange(undefined)}
            />
            <Button
              flexShrink={0}
              isActive={sexuality === Sexuality.STRAIGHT}
              onClick={() => handleSexualityChange(Sexuality.STRAIGHT)}
              size="sm"
              variant="outline"
            >
              {st('STRAIGHT.label')}
            </Button>
            <Button
              flexShrink={0}
              isActive={sexuality === Sexuality.BISEXUAL}
              onClick={() => handleSexualityChange(Sexuality.BISEXUAL)}
              size="sm"
              variant="outline"
            >
              {st('BISEXUAL.label')}
            </Button>
          </ButtonGroup>

          <ButtonGroup
            flexShrink={0}
            isAttached
            size="sm"
            variant="outline"
          >
            <IconButton
              aria-label=""
              icon={<Icon as={LuX} />}
              isActive={relationshipStyle === undefined}
              onClick={() => handleRelationshipStyleChange(undefined)}
            />
            <Button
              isActive={relationshipStyle === RelationshipStyle.MONOGAMY}
              onClick={() => handleRelationshipStyleChange(RelationshipStyle.MONOGAMY)}
            >
              {rst('MONOGAMY.label')}
            </Button>
            <Button
              isActive={relationshipStyle === RelationshipStyle.POLYAMORY}
              onClick={() => handleRelationshipStyleChange(RelationshipStyle.POLYAMORY)}
            >
              {rst('POLYAMORY.label')}
            </Button>
            <Button
              isActive={relationshipStyle === RelationshipStyle.POLYANDRY}
              onClick={() => handleRelationshipStyleChange(RelationshipStyle.POLYANDRY)}
            >
              {rst('POLYANDRY.label')}
            </Button>
            <Button
              isActive={relationshipStyle === RelationshipStyle.POLYGYNY}
              onClick={() => handleRelationshipStyleChange(RelationshipStyle.POLYGYNY)}
            >
              {rst('POLYGYNY.label')}
            </Button>
          </ButtonGroup>

          <ButtonGroup
            flexShrink={0}
            isAttached
            size="sm"
            variant="outline"
          >
            <IconButton
              aria-label=""
              icon={<Icon as={LuX} />}
              isActive={goal === undefined}
              onClick={() => handleGoalChange(undefined)}
            />
            <Button
              isActive={goal === Goal.FRIENDSHIP}
              onClick={() => handleGoalChange(Goal.FRIENDSHIP)}
            >
              {gt('FRIENDSHIP.label')}
            </Button>
            <Button
              isActive={goal === Goal.FUN}
              onClick={() => handleGoalChange(Goal.FUN)}
            >
              {gt('FUN.label')}
            </Button>
            <Button
              isActive={goal === Goal.LOVE}
              onClick={() => handleGoalChange(Goal.LOVE)}
            >
              {gt('LOVE.label')}
            </Button>
            <Button
              isActive={goal === Goal.MARRIAGE}
              onClick={() => handleGoalChange(Goal.MARRIAGE)}
            >
              {gt('MARRIAGE.label')}
            </Button>
            <Button
              isActive={goal === Goal.MODELLING}
              onClick={() => handleGoalChange(Goal.MODELLING)}
            >
              {gt('MODELLING.label')}
            </Button>
            <Button
              isActive={goal === Goal.PARTYING}
              onClick={() => handleGoalChange(Goal.PARTYING)}
            >
              {gt('PARTYING.label')}
            </Button>
            <Button
              isActive={goal === Goal.SEX}
              onClick={() => handleGoalChange(Goal.SEX)}
            >
              {gt('SEX.label')}
            </Button>
            <Button
              isActive={goal === Goal.TRAVEL}
              onClick={() => handleGoalChange(Goal.TRAVEL)}
            >
              {gt('TRAVEL.label')}
            </Button>
          </ButtonGroup>

          <Text fontSize="sm">
            {profilesResult?.length ?? 0}
            /
            {totalCount}
          </Text>
        </HStack>
      </Container>

      <Container
        flex={1}
        height="100%"
        maxW="lg"
        overflow="auto"
      >
        <ProfileGrid profilesResult={profilesResult} />
      </Container>

      <Container
        maxW="lg"
        pt={2}
      >
        {myProfileSnap?.data()?.status !== ProfileStatus.PUBLISHED ? (
          <Button
            className="profilesPublishButton"
            colorScheme="pink"
            isLoading={isPublishLoading}
            onClick={onPublishClick}
            width="100%"
          >
            {t('publishButton.default')}
          </Button>
        ) : (
          <Button
            className="profilesUnpublishButton"
            colorScheme="red"
            isLoading={isUnpublishLoading}
            onClick={onUnpublishClick}
            width="100%"
          >
            {t('unpublishButton.default')}
          </Button>
        )}
      </Container>
    </VStack>
  );
}

export function ProfilesScreenLoading() {
  const insets = useInsets();

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack gap={4}>
          <Skeleton flex={1} height={10} />
        </HStack>
      </Container>

      <Container
        flex={1}
        height="100%"
        maxW="lg"
        overflowY="auto"
        style={{
          paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
        }}
      >
        <ProfileGridLoading />
      </Container>
    </VStack>
  );
}

export default function ProfilesScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<ProfilesScreenLoading />}>
        <ProfilesScreenMain />
      </Suspense>
    </Catch>
  );
}
