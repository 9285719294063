import {
  Button,
  Container,
  HStack,
  VStack,
} from '@chakra-ui/react';
import {
  JobDoc,
  JobStatus,
  useJobsCollectionRef,
} from '@qupidu/hosting-common/src/common/collections/Jobs';
import { usePicturesCollectionRef } from '@qupidu/hosting-common/src/common/collections/Picture';
import ensureWriteAccess from '@qupidu/hosting-common/src/common/ensureWriteAccess';
import { useInsets } from '@qupidu/hosting-common/src/components/InsetsProvider';
import LogoFull from '@qupidu/hosting-common/src/components/LogoFull';
import NumberFormControl from '@qupidu/hosting-common/src/components/NumberFormControl';
import PicturesFormControl from '@qupidu/hosting-common/src/components/PicturesFormControl';
import {
  useMyProfileRef,
} from '@qupidu/hosting-common/src/components/refProviders/MyProfileRefProvider';
import TextareaFormControl from '@qupidu/hosting-common/src/components/TextareaFormControl';
import TextFormControl from '@qupidu/hosting-common/src/components/TextFormControl';
import useTelegramGetJobInvoiceUrl from '@qupidu/hosting-common/src/functions/useTelegramGetJobInvoiceUrl';
import {
  doc,
  DocumentReference,
  setDoc,
  Timestamp,
} from 'firebase/firestore';
import { Formik } from 'formik';
import { compact } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export type Props = {
  onComplete: (jobRef: DocumentReference<JobDoc>) => void;
};

export default function JobForm({ onComplete }: Props) {
  const { t } = useTranslation('JobCreateScreen', { keyPrefix: 'JobForm' });

  const schema = useMemo(
    () => yup.object().shape({
      description: yup
        .string()
        .label(t('description.label'))
        .required(),
      femaleParticipantPlaces: yup
        .number()
        .min(0)
        .max(1000)
        .label(t('femaleParticipantPlaces.label'))
        .required(),
      maleParticipantPlaces: yup
        .number()
        .min(0)
        .max(1000)
        .label(t('maleParticipantPlaces.label'))
        .required(),
      name: yup
        .string()
        .label(t('name.label'))
        .required(),
      pictureIds: yup
        .array()
        .label(t('pictureIds.label'))
        .compact()
        .min(3)
        .max(9)
        .of(
          yup
            .string()
            .label(t('pictureIds.item.label'))
            .required(),
        )
        .required(),
    }),
    [t],
  );

  const myProfileRef = useMyProfileRef();

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      description: '',
      femaleParticipantPlaces: 1,
      maleParticipantPlaces: 0,
      name: '',
      pictureIds: [],
      translations: {
        ru: {
          description: '',
          name: '',
        },
        uk: {
          description: '',
          name: '',
        },
      },
    }),
    [],
  );

  const jobsCollectionRef = useJobsCollectionRef();
  const picturesCollectionRef = usePicturesCollectionRef();

  const insets = useInsets();

  const telegramGetJobInvoiceUrl = useTelegramGetJobInvoiceUrl();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      await ensureWriteAccess();

      const jobRef = doc(jobsCollectionRef);

      await setDoc(
        jobRef,
        {
          _v: 1,
          createdAt: Timestamp.now(),
          description: values.description,
          femaleParticipantPlaces: values.femaleParticipantPlaces,
          maleParticipantPlaces: values.maleParticipantPlaces,
          name: values.name,
          organizerRef: myProfileRef,
          pictureRefs: compact(values.pictureIds).map((id) => doc(picturesCollectionRef, id)),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          status: JobStatus.DRAFTED,
          updatedAt: Timestamp.now(),
        },
      );

      const { data: { url } } = await telegramGetJobInvoiceUrl({ jobId: jobRef.id });

      await new Promise<'cancelled' | 'failed' | 'paid' | 'pending'>(
        (res) => { window.Telegram.WebApp.openInvoice(url, res); },
      );

      onComplete(jobRef);
    },
    [
      myProfileRef,
      onComplete,
      picturesCollectionRef,
      telegramGetJobInvoiceUrl,
      jobsCollectionRef,
    ],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        isValidating,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          gap={2}
          h="100%"
          minH={0}
          noValidate
          onSubmit={(e) => {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
          pt={`max(${insets.top}, var(--chakra-space-2))`}
        >
          <Container>
            <HStack h={12} justifyContent="center">
              <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
            </HStack>
          </Container>

          <Container
            flex={1}
            minH={0}
            overflowY="auto"
          >
            <VStack alignItems="stretch" gap={4}>
              <PicturesFormControl
                label={t('pictureIds.label')}
                name="pictureIds"
              />

              <TextFormControl
                label={t('name.label')}
                name="name"
              />

              <TextareaFormControl
                label={t('description.label')}
                name="description"
                placeholder={t('description.placeholder')}
                rows={10}
              />

              <NumberFormControl
                label={t('femaleParticipantPlaces.label')}
                max={10}
                min={0}
                name="femaleParticipantPlaces"
                step={1}
              />

              <NumberFormControl
                label={t('maleParticipantPlaces.label')}
                max={10}
                min={0}
                name="maleParticipantPlaces"
                step={1}
              />
            </VStack>
          </Container>

          <Container>
            <Button
              colorScheme={isValid ? 'indigo' : 'red'}
              isLoading={isValidating || isSubmitting}
              loadingText={t('createButton.loading')}
              type="submit"
              w="100%"
            >
              {t('createButton.default')}
            </Button>
          </Container>
        </VStack>
      )}
    </Formik>
  );
}
