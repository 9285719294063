import {
  AspectRatio,
  Box,
  Center,
  Container,
  Grid,
  Text,
} from '@chakra-ui/react';
import {
  JobStatus,
  useJobsCollectionRef,
} from '@qupidu/hosting-common/src/common/collections/Jobs';
import Catch from '@qupidu/hosting-common/src/components/Catch';
import useQuerySnapshot from '@qupidu/hosting-common/src/hooks/useQuerySnapshot';
import { limit, query, where } from 'firebase/firestore';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Job, { JobLoading } from './Job';

export function JobGridLoading() {
  return (
    <Grid gap={2} gridAutoRows="1fr" templateColumns="repeat(2, 1fr)">
      <AspectRatio ratio={9 / 16}>
        <JobLoading />
      </AspectRatio>
      <AspectRatio ratio={9 / 16}>
        <JobLoading />
      </AspectRatio>
      <AspectRatio ratio={9 / 16}>
        <JobLoading />
      </AspectRatio>
      <AspectRatio ratio={9 / 16}>
        <JobLoading />
      </AspectRatio>
    </Grid>
  );
}

export function JobGridMain() {
  const { t } = useTranslation('JobsScreen', { keyPrefix: 'JobList' });

  const jobsCollectionRef = useJobsCollectionRef();
  const { snap: jobsSnap } = useQuerySnapshot(
    query(
      jobsCollectionRef,
      where('status', '==', JobStatus.PUBLISHED),
      limit(100),
    ),
  );

  if (!jobsSnap) {
    return (
      <JobGridLoading />
    );
  }

  if (jobsSnap.docs.length) {
    return (
      <Grid autoRows="1fr" gap={2} templateColumns="repeat(2, 1fr)">
        {jobsSnap.docs.map((jobSnap) => (
          <AspectRatio
            key={jobSnap.id}
            ratio={9 / 16}
          >
            <Job
              jobSnap={jobSnap}
              scrollSnapAlign="center"
            />
          </AspectRatio>
        ))}

        {new Array(Math.ceil(jobsSnap.docs.length / 2) * 2 - jobsSnap.docs.length)
          .fill(null)
          .map((_, i) => (
            <Box
              _dark={{
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
              }}
              _light={{
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
              }}
              borderRadius="md"
              // eslint-disable-next-line react/no-array-index-key
              key={i}
            />
          ))}
      </Grid>
    );
  }

  return (
    <Container height="100%" maxW="lg">
      <Center height="100%">
        <Text textAlign="center">
          {t('emptyList.body')}
        </Text>
      </Center>
    </Container>
  );
}

export default function JobGrid() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<JobGridLoading />}>
        <JobGridMain />
      </Suspense>
    </Catch>
  );
}
