import { Center } from '@chakra-ui/react';
import { JobDoc } from '@qupidu/hosting-common/src/common/collections/Jobs';
import Catch from '@qupidu/hosting-common/src/components/Catch';
import LogoIcon from '@qupidu/hosting-common/src/components/LogoIcon';
import { DocumentReference } from 'firebase/firestore';
import { Suspense, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ErrorFallbackScreen from '../ErrorFallbackScreen';
import JobForm from './JobForm';

export function JobCreateScreenMain() {
  const navigate = useNavigate();

  useEffect(
    () => {
      const onClick = () => navigate('..');

      window.Telegram.WebApp.BackButton.onClick(onClick);
      window.Telegram.WebApp.BackButton.show();

      return () => {
        window.Telegram.WebApp.BackButton.hide();
        window.Telegram.WebApp.BackButton.offClick(onClick);
      };
    },
    [navigate],
  );

  const onComplete = useCallback(
    (jobRef: DocumentReference<JobDoc>) => {
      navigate(`/jobs/${jobRef.id}`);
    },
    [navigate],
  );

  return (
    <JobForm
      onComplete={onComplete}
    />
  );
}

export default function JobCreateScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <JobCreateScreenMain />
      </Suspense>
    </Catch>
  );
}
