import {
  AspectRatio,
  Box,
  Center,
  Container,
  Grid,
  Text,
} from '@chakra-ui/react';
import { ProfileIndexRecord } from '@qupidu/hosting-common/src/common/collections/Profiles';
import Catch from '@qupidu/hosting-common/src/components/Catch';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Profile, { ProfileLoading } from './Profile';

export function ProfileGridLoading() {
  return (
    <Grid gap={2} gridAutoRows="1fr" templateColumns="repeat(2, 1fr)">
      <AspectRatio ratio={9 / 16}>
        <ProfileLoading />
      </AspectRatio>
      <AspectRatio ratio={9 / 16}>
        <ProfileLoading />
      </AspectRatio>
      <AspectRatio ratio={9 / 16}>
        <ProfileLoading />
      </AspectRatio>
      <AspectRatio ratio={9 / 16}>
        <ProfileLoading />
      </AspectRatio>
    </Grid>
  );
}

export type Props = {
  profilesResult: ProfileIndexRecord[] | undefined;
};

export function ProfileGridMain({ profilesResult }: Props) {
  const { t } = useTranslation('ProfilesScreen', { keyPrefix: 'ProfileList' });

  if (!profilesResult) {
    return (
      <ProfileGridLoading />
    );
  }

  if (profilesResult.length) {
    return (
      <Grid autoRows="1fr" gap={2} templateColumns="repeat(2, 1fr)">
        {profilesResult.map((profileRecord) => (
          <AspectRatio
            key={profileRecord.objectID}
            ratio={9 / 16}
          >
            <Profile
              profileRecord={profileRecord}
              scrollSnapAlign="center"
            />
          </AspectRatio>
        ))}

        {new Array(Math.ceil(profilesResult.length / 2) * 2 - profilesResult.length)
          .fill(null)
          .map((_, i) => (
            <Box
              _dark={{
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
              }}
              _light={{
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
              }}
              borderRadius="md"
              // eslint-disable-next-line react/no-array-index-key
              key={i}
            />
          ))}
      </Grid>
    );
  }

  return (
    <Container height="100%" maxW="lg">
      <Center height="100%">
        <Text textAlign="center">
          {t('emptyList.body')}
        </Text>
      </Center>
    </Container>
  );
}

export default function ProfileGrid(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<ProfileGridLoading />}>
        <ProfileGridMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
