import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';

import type Preferences from '../Preferences';

import { useFirestore } from '../../components/FirestoreProvider';
import AppLanguage from '../AppLanguage';
import { type CityDoc } from './Cities';
import { type PictureDoc } from './Picture';
import { type ProfileDoc, type ProfileField } from './Profiles';

export enum VentureStatus {
  DRAFTED = 'DRAFTED',
  PAID = 'PAID',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export enum VentureLabel {
  DINNER = 'DINNER',
  NIGHTLIFE = 'NIGHTLIFE',
}

export type VentureDoc = {
  _v: 1;
  cityRef: DocumentReference<CityDoc>;
  createdAt: Timestamp;
  description: string;
  endsAt: Timestamp;
  femaleParticipantPlaces: number;
  femaleProfilePreferences?: Preferences;
  femaleProfileRequiredFields?: ProfileField[];
  indexedAt?: Timestamp;
  instagramTag?: string;
  labels: VentureLabel[];
  maleParticipantPlaces: number;
  maleProfilePreferences?: Preferences;
  maleProfileRequiredFields?: ProfileField[];
  name: string;
  organizerRef: DocumentReference<ProfileDoc>;
  pictureRefs: DocumentReference<PictureDoc>[];
  publishedAt?: Timestamp;
  startsAt: Timestamp;
  status: VentureStatus;
  translations?: Partial<Record<AppLanguage, Partial<Pick<VentureDoc, 'description' | 'name'>>>>;
  unpublishedAt?: Timestamp;
  updatedAt: Timestamp;
};

export const isVentureDoc = (
  doc?: DocumentData,
): doc is VentureDoc => true;

export const isVentureRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<VentureDoc> => ref.parent.id === 'ventures';

function isVentureSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<VentureDoc>;
function isVentureSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<VentureDoc>;
function isVentureSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<VentureDoc> | QueryDocumentSnapshot<VentureDoc> {
  return isVentureRef(snap.ref);
}

export { isVentureSnap };

export const getVenturesCollectionRef = (firestore: Firestore) => collection(firestore, 'ventures') as CollectionReference<VentureDoc>;

export const useVenturesCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => getVenturesCollectionRef(firestore), [firestore]);
};
