/* eslint-disable react/require-default-props */
import {
  Box,
  BoxProps,
  Center,
  HStack,
  Icon,
  IconButton,
} from '@chakra-ui/react';
import { animated, useSprings } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { clamp } from 'lodash';
import mixpanel from 'mixpanel-browser';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  LuHeart,
  LuHexagon,
  LuLayoutGrid,
  LuText,
  LuX,
} from 'react-icons/lu';

import { ProfileDoc } from '../../common/collections/Profiles';
import Catch from '../Catch';
import { useInsets } from '../InsetsProvider';
import LogoIcon from '../LogoIcon';
import PictureCell from '../PictureCell';
import InfoCell from './InfoCell';

export type Props = {
  height: number;
  onExit?: () => void;
  onSwipeLeft?: () => void;
  onSwipeRight?: () => void;
  profileSnap: QueryDocumentSnapshot<ProfileDoc>;
  width: number;
} & BoxProps;

export function ProfileCardMain({
  height,
  onExit,
  onSwipeLeft,
  onSwipeRight,
  profileSnap,
  width,
  ...boxProps
}: Props) {
  const ref = useRef<HTMLDivElement>(null);

  const { t } = useTranslation('ProfileCard');

  const profileDoc = useMemo(
    () => profileSnap.data(),
    [profileSnap],
  );

  const animationIndex = useRef(0);

  const [pages, api] = useSprings((profileDoc.pictureRefs ?? []).length + 1, (i) => ({
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: `rgb(from var(--chakra-colors-white) r g b / ${i === 0 ? 1 : (i === 1 ? 0.5 : 0.25)})`,
    display: 'block',
    x: i * width,
  }));

  const bind = useDrag(({
    active, cancel, direction: [xDir], movement: [mx],
  }) => {
    if (active && Math.abs(mx) > 100) {
      animationIndex.current = clamp(
        animationIndex.current + (xDir > 0 ? -1 : 1),
        0,
        (profileDoc.pictureRefs ?? []).length,
      );
      cancel();
    }

    api.start((i) => {
      if (i < animationIndex.current - 1 || i > animationIndex.current + 1) {
        return {
          backgroundColor: 'rgb(from var(--chakra-colors-white) r g b / 0.25)',
          display: 'none',
        };
      }

      const x = (i - animationIndex.current) * width + (active ? mx : 0);
      const backgroundColor = `rgb(from var(--chakra-colors-white) r g b / ${i !== animationIndex.current ? 0.5 : 1})`;
      return {
        backgroundColor,
        display: 'block',
        x,
      };
    });
  }, {
    axis: 'x',
    preventDefault: true,
    threshold: 20,
  });

  const handleDislikeClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      if (onSwipeLeft) {
        mixpanel.track('Profile Reject Clicked');
        onSwipeLeft();
      }
    },
    [onSwipeLeft],
  );

  const handleLikeClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      if (onSwipeRight) {
        mixpanel.track('Profile Accept Clicked');
        onSwipeRight();
      }
    },
    [onSwipeRight],
  );

  const insets = useInsets();

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      background="var(--chakra-colors-chakra-body-bg)"
      bottom={0}
      className="trip"
      h={`${height}px`}
      left={0}
      position="relative"
      right={0}
      top={0}
      w={`${width}px`}
    >
      {onExit ? (
        <IconButton
          aria-label={t('exitButton.default')}
          icon={<Icon as={LuLayoutGrid} />}
          onClick={onExit}
          position="absolute"
          right={4}
          size="lg"
          top="max(env(safe-area-inset-top), var(--chakra-space-4))"
          variant="solid"
          zIndex={10}
        />
      ) : null}

      <HStack
        alignItems="center"
        bottom={insets.bottom}
        gap={4}
        justifyContent="center"
        left={4}
        position="absolute"
        right={4}
        zIndex={10}
      >
        {pages.map(({ backgroundColor }, i) => (
          <Box
            as={animated.div}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            style={{ color: backgroundColor }}
            transition="color 0.15s ease-in-out"
          >
            {(i >= pages.length - 1) ? (<Icon as={LuText} boxSize={4} display="block" />) : null}
            {(i < pages.length - 1) ? (<Icon as={LuHexagon} boxSize={4} display="block" />) : null}
          </Box>
        ))}
      </HStack>

      {onSwipeLeft ? (
        <IconButton
          aria-label={t('dislikeButton.default')}
          bottom="max(env(safe-area-inset-bottom), var(--chakra-space-4))"
          icon={<Icon as={LuX} />}
          left={4}
          onClick={handleDislikeClick}
          position="absolute"
          size="lg"
          variant="solid"
          zIndex={10}
        />
      ) : null}

      {onSwipeRight ? (
        <IconButton
          aria-label={t('applyButton.default')}
          bottom="max(env(safe-area-inset-bottom), var(--chakra-space-4))"
          className="tripAction"
          colorScheme="pink"
          icon={<Icon as={LuHeart} />}
          onClick={handleLikeClick}
          position="absolute"
          right={4}
          size="lg"
          variant="solid"
          zIndex={10}
        />
      ) : null}

      <Box
        h={`${height}px`}
        overflow="hidden"
        position="relative"
        ref={ref}
        w={`${width}px`}
      >
        {pages.map(({ display, x }, i) => {
          if (i === pages.length - 1) {
            return (
              <InfoCell
                as={animated.div}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...bind()}
                height={height}
                key="info"
                position="absolute"
                profileSnap={profileSnap}
                style={{
                  display: display as unknown as string,
                  touchAction: 'none',
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  /* @ts-ignore */
                  x,
                }}
                width={width}
              />
            );
          }

          return (
            <PictureCell
              as={animated.div}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...bind()}
              height={height}
              key={(profileDoc.pictureRefs ?? [])[i].id}
              pictureRef={(profileDoc.pictureRefs ?? [])[i]}
              position="absolute"
              style={{
                display: display as unknown as string,
                touchAction: 'none',
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                /* @ts-ignore */
                x,
              }}
              width={width}
            />
          );
        })}
      </Box>
    </Box>
  );
}

export default function ProfileCard(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <ProfileCardMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
