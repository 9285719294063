import {
  Box,
  Button,
  Center,
  Container,
  HStack,
  Icon,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useProfilesCollectionRef } from '@qupidu/hosting-common/src/common/collections/Profiles';
import ensureWriteAccess from '@qupidu/hosting-common/src/common/ensureWriteAccess';
import Catch from '@qupidu/hosting-common/src/components/Catch';
import InsetsProvider, { useInsets } from '@qupidu/hosting-common/src/components/InsetsProvider';
import LogoFull from '@qupidu/hosting-common/src/components/LogoFull';
import LogoIcon from '@qupidu/hosting-common/src/components/LogoIcon';
import ProfileCard from '@qupidu/hosting-common/src/components/ProfileCard';
import useTelegramGetContactInvoiceUrl from '@qupidu/hosting-common/src/functions/useTelegramGetContactInvoiceUrl';
import useDimensions from '@qupidu/hosting-common/src/hooks/useDimensions';
import useDocumentSnapshot from '@qupidu/hosting-common/src/hooks/useDocumentSnapshot';
import useShowError from '@qupidu/hosting-common/src/hooks/useShowError';
import { doc } from 'firebase/firestore';
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuAlertTriangle } from 'react-icons/lu';
import { useNavigate, useParams } from 'react-router-dom';

import ErrorFallbackScreen from '../ErrorFallbackScreen';

export function ProfileScreenMain() {
  const navigate = useNavigate();
  useEffect(
    () => {
      const onClick = () => {
        window.Telegram.WebApp.HapticFeedback.impactOccurred('soft');

        navigate('..');
      };

      window.Telegram.WebApp.BackButton.onClick(onClick);
      window.Telegram.WebApp.BackButton.show();

      return () => {
        window.Telegram.WebApp.BackButton.hide();
        window.Telegram.WebApp.BackButton.offClick(onClick);
      };
    },
    [navigate],
  );

  const { t } = useTranslation('ProfileScreen');

  useEffect(
    () => {
      window.Telegram.WebApp.disableVerticalSwipes();

      return () => {
        window.Telegram.WebApp.enableVerticalSwipes();
      };
    },
    [],
  );

  const { profileId } = useParams<'profileId'>();

  const insets = useInsets();

  const [ref, { height, width }] = useDimensions();

  const profilesCollectionRef = useProfilesCollectionRef();
  const profileRef = useMemo(
    () => doc(profilesCollectionRef, profileId),
    [profileId, profilesCollectionRef],
  );

  const { snap: profileSnap } = useDocumentSnapshot(profileRef);

  const [isCreatingContactInvoice, setCreatingContactInvoice] = useState(false);
  const telegramGetContactInvoiceUrl = useTelegramGetContactInvoiceUrl();
  const toast = useToast();

  const handleContactClick = useCallback(
    () => {
      setCreatingContactInvoice(true);
      ensureWriteAccess()
        .then(() => telegramGetContactInvoiceUrl({ profileId: profileRef.id }))
        .then(({ data: { url } }) => new Promise<'cancelled' | 'failed' | 'paid' | 'pending'>(
          (res) => { window.Telegram.WebApp.openInvoice(url, res); },
        ))
        .then((status) => {
          switch (status) {
            case 'cancelled': {
              toast({
                isClosable: true,
                status: 'warning',
                title: t('buyContactButton.cancelled'),
              });
              break;
            }
            case 'paid': {
              toast({
                isClosable: true,
                status: 'success',
                title: t('buyContactButton.paid'),
              });
              break;
            }
            case 'pending': {
              toast({
                isClosable: true,
                status: 'loading',
                title: t('buyContactButton.pending'),
              });
              break;
            }
            case 'failed':
            default: {
              toast({
                isClosable: true,
                status: 'error',
                title: t('buyContactButton.failed'),
              });
              break;
            }
          }
        })
        .finally(() => setCreatingContactInvoice(false))
        .catch(useShowError);
    },
    [profileRef.id, t, telegramGetContactInvoiceUrl, toast],
  );

  if (!profileSnap || (!profileSnap.exists() && profileSnap.metadata.fromCache)) {
    return (
      <Center h="100%">
        <LogoIcon boxSize={16} />
      </Center>
    );
  }

  if (!profileSnap?.exists()) {
    return (
      <Center h="100%">
        <VStack>
          <Icon as={LuAlertTriangle} boxSize={10} />
          <Text>Profile not found</Text>
        </VStack>
      </Center>
    );
  }

  return (
    <VStack
      alignItems="stretch"
      gap={0}
      h="100%"
    >
      <Box
        flex={1}
        position="relative"
      >
        <Box
          left={0}
          position="absolute"
          right={0}
          top={`max(${insets.top}, var(--chakra-space-2))`}
          zIndex={50}
        >
          <Container maxW="lg">
            <HStack h={12} justifyContent="center">
              <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
            </HStack>
          </Container>
        </Box>

        <InsetsProvider
          bottom="var(--chakra-space-2)"
          left={insets.left}
          right={insets.right}
          top={`calc(max(${insets.top}, var(--chakra-space-2)) + var(--chakra-space-10))`}
        >
          <Container h="100%" maxW="lg" px={0} ref={ref}>
            {height && width ? (
              <ProfileCard
                height={height}
                profileSnap={profileSnap}
                width={width}
              />
            ) : null}
          </Container>
        </InsetsProvider>
      </Box>

      <Container
        maxW="lg"
        pt={2}
      >
        <Button
          className="buyContactButton"
          colorScheme="indigo"
          isLoading={isCreatingContactInvoice}
          onClick={handleContactClick}
          width="100%"
        >
          {t('buyContactButton.default')}
        </Button>
      </Container>
    </VStack>
  );
}

export default function ProfileScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <ProfileScreenMain />
      </Suspense>
    </Catch>
  );
}
