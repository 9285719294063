import base64URLencode from './base64URLencode';

export enum WebAppActionType {
  CREATE_ARRANGEMENT = 'create_arrangement',
  CREATE_JOB = 'create_job',
  CREATE_TRIP = 'create_trip',
  CREATE_VENTURE = 'create_venture',
  LIST_ARRANGEMENTS = 'list_arrangements',
  LIST_JOBS = 'list_jobs',
  LIST_KEYS = 'list_keys',
  LIST_PROFILES = 'list_profiles',
  LIST_TRIPS = 'list_trips',
  LIST_VENTURES = 'list_ventures',
  VIEW_ARRANGEMENT = 'view_arrangement',
  VIEW_ARRANGEMENT_APPLICATION = 'view_arrangement_application',
  VIEW_JOB = 'view_job',
  VIEW_JOB_APPLICATION = 'view_job_application',
  VIEW_KEY = 'view_key',
  VIEW_KEY_APPLICATION = 'view_key_application',
  VIEW_PROFILE = 'view_profile',
  VIEW_TRIP = 'view_trip',
  VIEW_TRIP_APPLICATION = 'view_trip_application',
  VIEW_VENTURE = 'view_venture',
  VIEW_VENTURE_APPLICATION = 'view_venture_application',
  WELCOME = 'welcome',
}

export type CreateArrangementAction = {
  action: WebAppActionType.CREATE_ARRANGEMENT;
};

export type CreateJobAction = {
  action: WebAppActionType.CREATE_JOB;
};

export type CreateTripAction = {
  action: WebAppActionType.CREATE_TRIP;
};

export type CreateVentureAction = {
  action: WebAppActionType.CREATE_VENTURE;
};

export type ListArrangementsAction = {
  action: WebAppActionType.LIST_ARRANGEMENTS;
};

export type ListJobsAction = {
  action: WebAppActionType.LIST_JOBS;
};

export type ListKeysAction = {
  action: WebAppActionType.LIST_KEYS;
};

export type ListProfilesAction = {
  action: WebAppActionType.LIST_PROFILES;
};

export type ListTripsAction = {
  action: WebAppActionType.LIST_TRIPS;
};

export type ListVenturesAction = {
  action: WebAppActionType.LIST_VENTURES;
};

export type ViewArrangementAction = {
  action: WebAppActionType.VIEW_ARRANGEMENT;
  arrangementId: string;
};

export type ViewArrangementApplicationAction = {
  action: WebAppActionType.VIEW_ARRANGEMENT_APPLICATION;
  applicationId: string;
  arrangementId: string;
};

export type ViewJobAction = {
  action: WebAppActionType.VIEW_JOB;
  jobId: string;
};

export type ViewJobApplicationAction = {
  action: WebAppActionType.VIEW_JOB_APPLICATION;
  applicationId: string;
  jobId: string;
};

export type ViewKeyAction = {
  action: WebAppActionType.VIEW_KEY;
  keyId: string;
};

export type ViewKeyApplicationAction = {
  action: WebAppActionType.VIEW_KEY_APPLICATION;
  applicationId: string;
  keyId: string;
};

export type ViewProfileAction = {
  action: WebAppActionType.VIEW_PROFILE;
  profileId: string;
};

export type ViewTripAction = {
  action: WebAppActionType.VIEW_TRIP;
  tripId: string;
};

export type ViewTripApplicationAction = {
  action: WebAppActionType.VIEW_TRIP_APPLICATION;
  applicationId: string;
  tripId: string;
};

export type ViewVentureAction = {
  action: WebAppActionType.VIEW_VENTURE;
  ventureId: string;
};

export type ViewVentureApplicationAction = {
  action: WebAppActionType.VIEW_VENTURE_APPLICATION;
  applicationId: string;
  ventureId: string;
};

export type WelcomeAction = {
  action: WebAppActionType.WELCOME;
};

type WebAppAction =
  | CreateArrangementAction
  | CreateJobAction
  | CreateTripAction
  | CreateVentureAction
  | ListArrangementsAction
  | ListJobsAction
  | ListKeysAction
  | ListProfilesAction
  | ListTripsAction
  | ListVenturesAction
  | ViewArrangementAction
  | ViewArrangementApplicationAction
  | ViewJobAction
  | ViewJobApplicationAction
  | ViewKeyAction
  | ViewKeyApplicationAction
  | ViewProfileAction
  | ViewTripAction
  | ViewTripApplicationAction
  | ViewVentureAction
  | ViewVentureApplicationAction
  | WelcomeAction;

export default WebAppAction;

export function getTelegramAppLinkData(data?: WebAppAction): string {
  const url = new URL('https://t.me/qupidubot');
  if (data) {
    url.searchParams.set('startapp', base64URLencode(JSON.stringify(data)));
  } else {
    url.searchParams.set('startapp', '');
  }

  return url.toString();
}
