import {
  Button,
  Container,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { ProfileVersionDoc } from '@qupidu/hosting-common/src/common/collections/Profiles';
import { useInsets } from '@qupidu/hosting-common/src/components/InsetsProvider';
import LogoFull from '@qupidu/hosting-common/src/components/LogoFull';
import TextFormControl from '@qupidu/hosting-common/src/components/TextFormControl';
import { Formik } from 'formik';
import moment from 'moment';
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export type Props = {
  data: Pick<ProfileVersionDoc, 'birthDate'> | undefined;
  onComplete: (data: Required<Pick<ProfileVersionDoc, 'birthDate'>>) => void;
};

export function BirthDateFormMain({ data, onComplete }: Props) {
  const { t } = useTranslation('WelcomeScreen', { keyPrefix: 'ProfileForm' });

  const birthDateText = useCallback(
    (
      value?: string,
    ) => {
      if (!value) {
        return false;
      }

      const current = moment(value);

      if (!current.isValid()) {
        return false;
      }

      const lowest = moment().subtract(16, 'years').startOf('day');
      const highest = moment().subtract(70, 'years').endOf('day');

      const daysOlderThanLowest = moment.duration(moment(lowest).diff(current)).asDays();
      const daysYoungerThanHighest = moment.duration(moment(current).diff(highest)).asDays();

      if (daysOlderThanLowest < 0) {
        return false;
      }

      if (daysYoungerThanHighest < 0) {
        return false;
      }

      return true;
    },
    [],
  );

  const schema = useMemo(
    () => yup.object().shape({
      birthDate: yup
        .string()
        .label(t('birthDate.label'))
        .test(birthDateText)
        .required(),
    }),
    [t, birthDateText],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      birthDate: data?.birthDate ?? '',
    }),
    [data],
  );

  const insets = useInsets();

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onComplete}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        isValidating,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          gap={2}
          h="100%"
          minH={0}
          noValidate
          onSubmit={(e) => {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
          pb={`max(${insets.bottom}, var(--chakra-space-2))`}
          pt={`max(${insets.top}, var(--chakra-space-2))`}
        >
          <Container>
            <HStack h={12} justifyContent="center">
              <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
            </HStack>
          </Container>

          <Container
            flex={1}
            minH={0}
            overflow="auto"
          >
            <VStack alignItems="stretch" flex={1} gap={8} overflow="auto">
              <TextFormControl
                autoComplete="bday"
                helperText={t('birthDate.helperText')}
                label={t('birthDate.label')}
                name="birthDate"
                type="date"
              />
            </VStack>
          </Container>

          <Container>
            <Button
              colorScheme={isValid ? undefined : 'red'}
              isLoading={isValidating || isSubmitting}
              type="submit"
              w="100%"
            >
              {isValid ? t('nextButton.default') : t('nextButton.invalid')}
            </Button>
          </Container>
        </VStack>
      )}
    </Formik>
  );
}

export default function BirthDateForm(props: Props) {
  return (
    <Suspense fallback={null}>
      <BirthDateFormMain
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Suspense>
  );
}
