import {
  Button,
  Container,
  Heading,
  HStack,
  Skeleton,
  VStack,
} from '@chakra-ui/react';
import Catch from '@qupidu/hosting-common/src/components/Catch';
import { useInsets } from '@qupidu/hosting-common/src/components/InsetsProvider';
import useJoyrideLocale from '@qupidu/hosting-common/src/hooks/useJoyrideLocale';
import useJoyrideStyles from '@qupidu/hosting-common/src/hooks/useJoyrideStyles';
import mixpanel from 'mixpanel-browser';
import { Suspense, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import { useNavigate } from 'react-router-dom';

import ErrorFallbackScreen from '../ErrorFallbackScreen';
import ArrangementGrid, { ArrangementGridLoading } from './ArrangementGrid';

export function ArrangementsScreenMain() {
  const { t } = useTranslation('ArrangementsScreen');

  const insets = useInsets();

  const navigate = useNavigate();
  const onBecomeSponsorClick = useCallback(
    () => {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
      mixpanel.track('Become Sponsor Clicked');
      navigate('create');
    },
    [navigate],
  );

  const locale = useJoyrideLocale();

  const styles = useJoyrideStyles();

  const steps = useMemo<Step[]>(() => ([
    {
      content: t('tour.welcome.content'),
      placement: 'center',
      target: 'body',
      title: t('tour.welcome.title'),
    },
    {
      content: t('tour.arrangementsSponsorButton.content'),
      target: '.arrangementsSponsorButton',
    },
    {
      content: t('tour.arrangementItem.content'),
      target: '.arrangementItem',
    },
  ]), [t]);

  const tourRun = useMemo(() => window.localStorage.getItem('joyride/ArrangementsScreenComplete') !== 'TRUE', []);

  const handleTourChange = useCallback(
    ({ type }: CallBackProps) => {
      if (type === 'tour:end') {
        window.localStorage.setItem('joyride/ArrangementsScreenComplete', 'TRUE');
      }
    },
    [],
  );

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack h={12} justifyContent="center">
          <Heading fontSize="2xl">
            {t('title')}
          </Heading>
        </HStack>
      </Container>

      <Joyride
        callback={handleTourChange}
        continuous
        hideCloseButton
        locale={locale}
        run={tourRun}
        showSkipButton
        steps={steps}
        styles={styles}
      />

      <Container
        flex={1}
        height="100%"
        maxW="lg"
        overflow="auto"
      >
        <ArrangementGrid />
      </Container>

      <Container
        maxW="lg"
        pt={2}
      >
        <Button
          className="arrangementsSponsorButton"
          colorScheme="indigo"
          onClick={onBecomeSponsorClick}
          width="100%"
        >
          {t('becomeSponsorButton.default')}
        </Button>
      </Container>
    </VStack>
  );
}

export function ArrangementsScreenLoading() {
  const insets = useInsets();

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack gap={4}>
          <Skeleton flex={1} height={10} />
        </HStack>
      </Container>

      <Container
        flex={1}
        height="100%"
        maxW="lg"
        overflowY="auto"
        style={{
          paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
        }}
      >
        <ArrangementGridLoading />
      </Container>
    </VStack>
  );
}

export default function ArrangementsScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<ArrangementsScreenLoading />}>
        <ArrangementsScreenMain />
      </Suspense>
    </Catch>
  );
}
