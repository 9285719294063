import {
  Button,
  Container,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ProfileVersionDoc } from '@qupidu/hosting-common/src/common/collections/Profiles';
import CupSize from '@qupidu/hosting-common/src/common/CupSize';
import PantiesSize from '@qupidu/hosting-common/src/common/PantiesSize';
import CupSizeFormControl from '@qupidu/hosting-common/src/components/CupSizeFormControl';
import { useInsets } from '@qupidu/hosting-common/src/components/InsetsProvider';
import LogoFull from '@qupidu/hosting-common/src/components/LogoFull';
import NumberFormControl from '@qupidu/hosting-common/src/components/NumberFormControl';
import PantiesSizeFormControl from '@qupidu/hosting-common/src/components/PantiesSizeFormControl';
import { Formik } from 'formik';
import { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export type Props = {
  data: Pick<ProfileVersionDoc, 'cupSize' | 'height' | 'pantiesSize' | 'weight'> | undefined;
  onComplete: (data: Required<Pick<ProfileVersionDoc, 'cupSize' | 'height' | 'pantiesSize' | 'weight'>>) => void;
};

export function BodyFormMain({ data, onComplete }: Props) {
  const { t } = useTranslation('WelcomeScreen', { keyPrefix: 'ProfileForm' });

  const schema = useMemo(
    () => yup.object().shape({
      cupSize: yup
        .number()
        .label(t('cupSize.label'))
        .integer()
        .min(CupSize.AA)
        .max(CupSize.E)
        .required(),
      height: yup
        .number()
        .label(t('height.label'))
        .integer()
        .min(30)
        .max(300)
        .required(),
      pantiesSize: yup
        .number()
        .label(t('pantiesSize.label'))
        .integer()
        .min(PantiesSize.XXS)
        .max(PantiesSize.XXL)
        .required(),
      weight: yup
        .number()
        .label(t('weight.label'))
        .integer()
        .min(30)
        .max(300)
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      cupSize: data?.cupSize ?? CupSize.AA,
      height: data?.height ?? 150,
      pantiesSize: data?.pantiesSize ?? PantiesSize.XXL,
      weight: data?.weight ?? 120,
    }),
    [data],
  );

  const insets = useInsets();

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onComplete}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        isValidating,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          gap={2}
          h="100%"
          minH={0}
          noValidate
          onSubmit={(e) => {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
          pb={`max(${insets.bottom}, var(--chakra-space-2))`}
          pt={`max(${insets.top}, var(--chakra-space-2))`}
        >
          <Container>
            <HStack h={12} justifyContent="center">
              <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
            </HStack>
          </Container>

          <Container
            flex={1}
            minH={0}
            overflow="auto"
          >
            <VStack alignItems="stretch" flex={1} gap={8} overflow="auto">
              <Grid columnGap={4} rowGap={2} templateColumns="repeat(2, 1fr)">
                <NumberFormControl
                  label={t('height.label')}
                  max={250}
                  min={150}
                  name="height"
                  right={t('height.unit')}
                  step={1}
                />

                <NumberFormControl
                  label={t('weight.label')}
                  max={120}
                  min={45}
                  name="weight"
                  right={t('weight.unit')}
                  step={1}
                />

                <GridItem colSpan={2}>
                  <Text
                    _dark={{ color: 'whiteAlpha.600' }}
                    fontSize="sm"
                  >
                    {t('heightAndWeight.helperText')}
                  </Text>
                </GridItem>
              </Grid>

              <Grid columnGap={4} rowGap={2} templateColumns="repeat(2, 1fr)">
                <CupSizeFormControl
                  label={t('cupSize.label')}
                  name="cupSize"
                />

                <PantiesSizeFormControl
                  label={t('pantiesSize.label')}
                  name="pantiesSize"
                />

                <GridItem colSpan={2}>
                  <Text
                    _dark={{ color: 'whiteAlpha.600' }}
                    fontSize="sm"
                  >
                    {t('bikiniSize.helperText')}
                  </Text>
                </GridItem>
              </Grid>
            </VStack>
          </Container>

          <Container>
            <Button
              colorScheme={isValid ? undefined : 'red'}
              isLoading={isValidating || isSubmitting}
              type="submit"
              w="100%"
            >
              {isValid ? t('nextButton.default') : t('nextButton.invalid')}
            </Button>
          </Container>
        </VStack>
      )}
    </Formik>
  );
}

export default function BodyForm(props: Props) {
  return (
    <Suspense fallback={null}>
      <BodyFormMain
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Suspense>
  );
}
