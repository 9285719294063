import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';

import { useFunctions } from '../components/FunctionsProvider';

export type Data = {
  ventureId: string;
};

export type Result = {
  url: string;
};

export default function useTelegramGetVentureInvoiceUrl() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Data, Result>(functions, 'telegram-getVentureInvoiceUrl'), [functions]);
}
