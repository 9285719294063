import { Center } from '@chakra-ui/react';
import { useProfilesCollectionRef } from '@qupidu/hosting-common/src/common/collections/Profiles';
import { useAuth } from '@qupidu/hosting-common/src/components/AuthProvider';
import Catch from '@qupidu/hosting-common/src/components/Catch';
import LogoIcon from '@qupidu/hosting-common/src/components/LogoIcon';
import MyProfileRefProvider from '@qupidu/hosting-common/src/components/refProviders/MyProfileRefProvider';
import * as Sentry from '@sentry/react';
import { User } from 'firebase/auth';
import { doc } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import {
  Suspense,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Outlet } from 'react-router-dom';

import ErrorFallbackScreen from './ErrorFallbackScreen';

export type Props = {
  fallback: React.ReactElement;
};

export function CheckMyProfileRefLayoutMain({ fallback }: Props) {
  const auth = useAuth();

  const [user, setUser] = useState<null | User>(null);

  useEffect(
    () => auth.onAuthStateChanged(setUser),
    [auth],
  );

  const [authStateReady, setAuthStateReady] = useState<boolean>(false);

  useEffect(
    () => {
      auth.authStateReady()
        .then(() => setAuthStateReady(true))
        .catch(() => { });
    },
    [auth],
  );

  const profilesCollectionRef = useProfilesCollectionRef();

  const myProfileRef = useMemo(
    () => (user?.uid ? doc(profilesCollectionRef, user.uid) : undefined),
    [profilesCollectionRef, user?.uid],
  );

  useEffect(
    () => {
      if (myProfileRef?.id) {
        mixpanel.identify(myProfileRef.id);
        Sentry.setUser({ id: myProfileRef.id });
      }
    },
    [myProfileRef?.id],
  );

  if (!authStateReady) {
    return (
      <Center h="100%"><LogoIcon boxSize={16} /></Center>
    );
  }

  if (!myProfileRef) {
    return fallback;
  }

  return (
    <MyProfileRefProvider
      profileRef={myProfileRef}
    >
      <Outlet />
    </MyProfileRefProvider>
  );
}

export default function CheckMyProfileRefLayout(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <CheckMyProfileRefLayoutMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
