import {
  Box,
  BoxProps,
  Container,
  Heading,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import AppLanguage from '@qupidu/hosting-common/src/common/AppLanguage';
import { TripDoc } from '@qupidu/hosting-common/src/common/collections/Trips';
import Catch from '@qupidu/hosting-common/src/components/Catch';
import PictureCell from '@qupidu/hosting-common/src/components/PictureCell';
import useDimensions from '@qupidu/hosting-common/src/hooks/useDimensions';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export type Props = {
  tripSnap: QueryDocumentSnapshot<TripDoc>;
} & BoxProps;

export function TripMain({
  tripSnap, ...boxProps
}: Props) {
  const { i18n, t } = useTranslation('TripsScreen', { keyPrefix: 'Trip' });

  const navigate = useNavigate();
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      mixpanel.track('Trip Clicked');

      window.Telegram.WebApp.HapticFeedback.impactOccurred('soft');

      navigate(`/trips/${tripSnap.id}`);
    },
    [navigate, tripSnap.id],
  );

  const tripDoc = useMemo(() => tripSnap.data(), [tripSnap]);

  const [ref, { height: h, width: w }] = useDimensions();

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      borderRadius="lg"
      className="tripItem"
      h="100%"
      onClick={handleClick}
      overflow="hidden"
      position="relative"
      ref={ref}
    >
      <PictureCell
        bottom={0}
        height={h ?? 1600}
        left={0}
        pictureRef={tripDoc.pictureRefs[0]}
        position="absolute"
        right={0}
        top={0}
        width={w ?? 900}
        zIndex={-10}
      />

      <VStack
        alignItems="stretch"
        bottom={0}
        flexGrow={0}
        flexShrink={0}
        justifyContent="end"
        left={0}
        position="absolute"
        right={0}
        top={0}
      >
        <VStack
          alignItems="center"
          background="linear-gradient(0deg, var(--chakra-colors-chakra-body-bg) 0%, rgba(0, 0, 0, 0) 100%);"
          gap={1}
          py={5}
        >
          <Container maxW="lg">
            <Heading
              as="h1"
              fontSize="xl"
              fontWeight="bold"
              textAlign="center"
            >
              {/* eslint-disable-next-line max-len */}
              {(tripDoc.translations && tripDoc.translations[i18n.language as AppLanguage]?.name) ?? tripDoc.name}
            </Heading>
          </Container>

          <Text
            fontSize="sm"
            opacity={0.75}
          >
            {t('dates.value', {
              endsAt: tripDoc.endsAt.toDate(),
              formatParams: {
                endsAt: { day: 'numeric', month: 'short', year: 'numeric' },
                startsAt: { day: 'numeric', month: 'short' },
              },
              startsAt: tripDoc.startsAt.toDate(),
            })}
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
}

export function TripLoading({ ...boxProps }: BoxProps) {
  return (
    <Skeleton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      borderRadius="lg"
      h="100%"
    />
  );
}

export default function Trip({ tripSnap, ...boxProps }: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={(
        <TripLoading
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
        />
      )}
      >
        <TripMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
          tripSnap={tripSnap}
        />
      </Suspense>
    </Catch>
  );
}
