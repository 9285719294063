import {
  Avatar,
  Box,
  Center,
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import Catch from '@qupidu/hosting-common/src/components/Catch';
import { useInsets } from '@qupidu/hosting-common/src/components/InsetsProvider';
import LogoIcon from '@qupidu/hosting-common/src/components/LogoIcon';
import PictureAvatar from '@qupidu/hosting-common/src/components/PictureAvatar';
import {
  useMyProfileRef,
} from '@qupidu/hosting-common/src/components/refProviders/MyProfileRefProvider';
import useDocumentSnapshot from '@qupidu/hosting-common/src/hooks/useDocumentSnapshot';
import { compact } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { Suspense, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuPen, LuUser } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';

import ErrorFallbackScreen from '../ErrorFallbackScreen';
import TripGrid from './TripGrid';

export function MyProfileScreenMain() {
  const insets = useInsets();

  const { t } = useTranslation('MyProfileScreen');

  const myProfileRef = useMyProfileRef();
  const { snap: myProfileSnap } = useDocumentSnapshot(myProfileRef);
  const myProfileDoc = useMemo(() => myProfileSnap?.data(), [myProfileSnap]);

  const navigate = useNavigate();
  const handleUpdateClick = useCallback(
    () => {
      navigate('update');
      mixpanel.track('My Profile Update Clicked');
    },
    [navigate],
  );

  const handlePreviewClick = useCallback(
    () => {
      navigate(`/profiles/${myProfileRef.id}`);
      mixpanel.track('My Profile Preview Clicked');
    },
    [myProfileRef.id, navigate],
  );

  return (
    <VStack
      alignItems="stretch"
      gap={0}
      h="100%"
      pl={insets.left}
      pr={insets.right}
    >
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack h={12} justifyContent="center">
          <Heading fontSize="2xl">
            {t('title')}
          </Heading>
        </HStack>
      </Container>

      <Container
        flex={1}
        maxW="lg"
        overflowY="auto"
        py={2}
      >
        <VStack alignItems="stretch" gap={8}>
          <VStack alignItems="center" gap={0}>
            <Box boxSize={64} position="relative">
              {myProfileDoc?.pictureRefs?.length ? (
                <PictureAvatar
                  boxSize={48}
                  icon={<Icon as={LuUser} />}
                  loading="lazy"
                  margin={8}
                  onClick={handlePreviewClick}
                  pictureRef={myProfileDoc.pictureRefs[0]}
                  position="absolute"
                  size="2xl"
                />
              ) : (
                <Avatar
                  boxSize={48}
                  icon={<Icon as={LuUser} />}
                  margin={8}
                  position="absolute"
                  size="2xl"
                />
              )}

              <IconButton
                aria-label={t('updateProfileButton.default')}
                borderRadius="full"
                colorScheme="deepPurple"
                icon={<Icon as={LuPen} />}
                onClick={handleUpdateClick}
                position="absolute"
                right={10}
                size="lg"
                top={10}
              />
            </Box>

            <HStack gap={4}>
              <Text fontSize="3xl" fontWeight="bold" lineHeight={1}>
                {myProfileDoc ? compact([myProfileDoc.name, myProfileDoc.age]).join(', ') : 'Incognito'}
              </Text>
            </HStack>
          </VStack>

          <TripGrid />
        </VStack>
      </Container>
    </VStack>
  );
}

export default function MyProfileScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <MyProfileScreenMain />
      </Suspense>
    </Catch>
  );
}
