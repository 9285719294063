import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';

import { useFunctions } from '../components/FunctionsProvider';

export type Data = {
  profileId: string;
};

export type Result = {
  url: string;
};

export default function useTelegramGetContactInvoiceUrl() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Data, Result>(functions, 'telegram-getContactInvoiceUrl'), [functions]);
}
